'use client';

import { createContext, useContext } from 'react';

export interface RouterContextState {
  pathname: string;
  basePath: string;
  goBack: () => void;
  linkLegacyBehavior: boolean;
  host?: string;
  url?: string;
}

export const defaultRouterContextState: RouterContextState = {
  pathname: '',
  basePath: '',
  goBack: () => {
    console.log('goBack not implemented');
  },
  linkLegacyBehavior: false,
  host: '',
  url: '',
};

export const RouterContext = createContext<RouterContextState>(
  defaultRouterContextState
);

export const useRouter = () => useContext(RouterContext);

export const RouterProvider = ({
  value,
  children,
}: {
  value: RouterContextState;
  children: React.ReactElement;
}) => {
  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};
