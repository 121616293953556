import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { AELink, useGetAELink } from '@ae/shared';
import { useViewport } from '@ae/shared-ui';

const InfoSection = () => {
  const { getLinkProps, getLinkTitle } = useGetAELink();
  const { isMobile, isDesktop } = useViewport();

  return (
    <Box data-testid="essential-texts-and-links">
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing="20px"
        alignItems="stretch"
        divider={
          isDesktop ? (
            <Text mx="15px" color="white">
              -
            </Text>
          ) : undefined
        }
      >
        {[
          AELink.Conditions,
          AELink.Insurance,
          AELink.ConfidentialPolicy,
          AELink.Dsa,
        ].map((link) => {
          //https://github.com/mui/material-ui/issues/39833
          const { key, ...linkProps } = getLinkProps(link);
          return (
            <Link color="white" key={key} {...linkProps}>
              {getLinkTitle(link)}
            </Link>
          );
        })}
      </Stack>

      <Text mt={isMobile ? '2.7rem' : '8px'} color="white">
        <Text as="span" color="rgba(255,255,255,.7)">
          {`©2025 Ardennes-Etape (Asteria SRL) — `}
        </Text>
        Avenue Constant Grandprez 29, B-4970 Stavelot - TVA BE 0473952 094
      </Text>
    </Box>
  );
};

export default InfoSection;
