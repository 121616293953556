'use client';

import { createContext, useContext, useRef } from 'react';
import { format } from 'date-fns';
import { createStore, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  StayDateAvailableJsonldReadStayDateAvailable,
  StayDateAvailableJsonldReadStayDateAvailableStayType,
} from '@ae/data-access';
import { StayDate } from '@ae/shared-comp';
import { immer } from 'zustand/middleware/immer';
import { parseStayDates } from '../hooks';
import { ParsedStayDates, PerStayTypeStayDates } from './types';

const generateId = (stayDate: StayDate) => {
  if (!stayDate.startDate || !stayDate.endDate) {
    return stayDate.stayType;
  }

  const formatDate = (date: Date | undefined) =>
    date ? format(date, 'yyyy-MM-dd') : '';

  return `${formatDate(stayDate.startDate)}|${formatDate(stayDate.endDate)}-${
    stayDate.stayType
  }`;
};

// alias
type StayType = StayDateAvailableJsonldReadStayDateAvailableStayType;

interface CalendarProps {
  selectedStayDate: StayDate | null;
  selectedStayType: StayType;
  stayDates: PerStayTypeStayDates;
  oldestStayDate: StayDate | null;
  newestStayDate: StayDate | null;
  months: Date[];
}

interface CalendarState extends CalendarProps {
  getStayDates: (day: Date, selectedStayType?: StayType) => StayDate[];
  setSelectedStayDate: (stayDate: StayDate | null) => void;
  setSelectedStayType: (stayType: StayType) => void;
  setParsedStayDates: (value: ParsedStayDates) => void;
}

type CalendarStore = ReturnType<typeof createCalendarStore>;

const createCalendarStore = (initProps?: Partial<CalendarProps>) => {
  const DEFAULT_PROPS: CalendarProps = {
    selectedStayDate: null,
    selectedStayType: StayDateAvailableJsonldReadStayDateAvailableStayType.wk,
    stayDates: {
      wk: {},
      lw: {},
      mw: {},
      '1w': {},
      '2w': {},
      custom: {},
    },
    oldestStayDate: null,
    newestStayDate: null,
    months: [],
  };

  return createStore<CalendarState>()(
    devtools(
      immer((set, get) => ({
        ...DEFAULT_PROPS,
        ...initProps,
        selectedStayDate: initProps?.selectedStayDate
          ? ({
              ...initProps.selectedStayDate,
              id: generateId(initProps.selectedStayDate),
            } as StayDate)
          : null,
        getStayDates: (day: Date, selectedStayType?: StayType) =>
          get().stayDates[selectedStayType ?? get().selectedStayType]?.[
            day.getFullYear()
          ]?.[day.getMonth()] ?? [],
        setSelectedStayDate: (stayDate: StayDate | null) => {
          set((state) => {
            state.selectedStayDate = stayDate
              ? {
                  ...stayDate,
                  id: generateId(stayDate),
                }
              : null;
          });
        },
        setSelectedStayType: (stayType: StayType) =>
          set((state) => {
            state.selectedStayType = stayType;
          }),
        setParsedStayDates: (value: ParsedStayDates) => {
          set((state) => {
            state.stayDates = value.stayDates;
            state.oldestStayDate = value.oldestStayDate;
            state.newestStayDate = value.newestStayDate;
            state.months = value.months;
          });
        },
      }))
    )
  );
};

const CalendarContext = createContext<CalendarStore | null>(null);

export const CalendarStayDatesProvider = ({
  stayDates,
  children,
}: {
  stayDates: StayDateAvailableJsonldReadStayDateAvailable[];
} & React.PropsWithChildren) => {
  const storeRef = useRef<CalendarStore>();

  if (!storeRef.current) {
    const parsedValues = parseStayDates(stayDates);

    storeRef.current = createCalendarStore({
      stayDates: parsedValues.stayDates,
      oldestStayDate: parsedValues.oldestStayDate,
      newestStayDate: parsedValues.newestStayDate,
      months: parsedValues.months,
    });
  }

  return (
    <CalendarContext.Provider value={storeRef.current}>
      {children}
    </CalendarContext.Provider>
  );
};

function useCalendarStore<T>(selector: (state: CalendarState) => T): T {
  const store = useContext(CalendarContext);
  if (!store) throw new Error('Missing CalendarContext.Provider in the tree');
  return useStore(store, selector);
}

export const useMonths = () => useCalendarStore((s) => s.months);
export const useSelectedStayDate = () =>
  useCalendarStore((s) => s.selectedStayDate);
export const useSelectedStayType = () =>
  useCalendarStore((s) => s.selectedStayType);
export const useGetStayDates = () => useCalendarStore((s) => s.getStayDates);
export const useSetSelectedStayDate = () =>
  useCalendarStore((s) => s.setSelectedStayDate);
export const useSetSelectedStayType = () =>
  useCalendarStore((s) => s.setSelectedStayType);
export const useOldestStayDate = () =>
  useCalendarStore((s) => s.oldestStayDate);
export const useNewestStayDate = () =>
  useCalendarStore((s) => s.newestStayDate);
