import { AELink, useGetAELink, useTranslation } from '@ae/shared';
import Menu from './Menu';
import Title from './Title';

const HostSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkTitle } = useGetAELink();

  return (
    <>
      <Title>{t('footer.host')}</Title>
      <Menu>
        {[AELink.Owner, AELink.BecomeOwner, AELink.BlogOwner].map((link) => {
          //https://github.com/mui/material-ui/issues/39833
          const { key, ...linkProps } = getLinkProps(link);
          return (
            <Menu.MenuItem key={key} {...linkProps}>
              {getLinkTitle(link)}
            </Menu.MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default HostSection;
