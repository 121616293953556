import { useCallback, useEffect, useRef } from 'react';
import { useApiFavoritesListGetCollection } from '@ae/data-access';
import { useMe } from '@ae/shared';

type Props = {
  children: React.ReactElement | null;
  selectedRentalId: string;
};

/**
 * RentalFavoritesNotifier is a component that notifies whether a selected rental is in the user's favorites list.
 *
 * Props:
 * - children: React.ReactElement | null - The child components to be rendered.
 * - selectedRentalId: string - The ID of the rental to check in the favorites list.
 *
 * Behavior:
 * - The component uses the `useMe` hook to check if the user is authenticated.
 * - It fetches the user's favorites list using the `useApiFavoritesListGetCollection` hook.
 * - When the favorites list is loaded, it checks if the `selectedRentalId` is in the favorites list.
 * - A custom event `rentalInFavoritesResponse` is dispatched with the rental ID and a boolean indicating whether it is in the favorites list.
 * - The component maintains a queue of rental IDs to process when the favorites list is loaded.
 * - The component listens for custom events `rentalInFavoritesQuery` and `rentalInFavoritesQueriesReady`.
 * - When a `rentalInFavoritesQuery` event is received, the component adds the rental ID to the queue.
 * - When a `rentalInFavoritesQueriesReady` event is received, the component processes the queue of rental IDs.
 */
export const RentalFavoritesNotifier = ({
  children,
  selectedRentalId,
}: Props) => {
  const rentalIdsToProcessQueue = useRef(new Set());
  const { authenticated } = useMe();
  const { data: favoritesList, isLoading: isFavoritesListLoading } =
    useApiFavoritesListGetCollection(
      {
        pagination: false,
      },
      {
        query: {
          enabled: authenticated,
        },
      }
    );

  useEffect(() => {
    if (favoritesList && favoritesList['hydra:member'].length) {
      if (selectedRentalId.length) {
        rentalIdsToProcessQueue.current.add(selectedRentalId);
      }
      processQueryAndNotify();
    }
  }, [favoritesList]);

  const processQueryAndNotify = useCallback(() => {
    if (
      !isFavoritesListLoading &&
      favoritesList &&
      rentalIdsToProcessQueue.current.size > 0
    ) {
      const rentalId = rentalIdsToProcessQueue.current.values().next()
        .value as string;

      const isRentalInFavoritesList = (rentalId: string) =>
        favoritesList?.['hydra:member']
          .flatMap((el) => el.favorites)
          .find((id) => rentalId === String(id)) !== undefined;

      document.dispatchEvent(
        new CustomEvent('rentalInFavoritesResponse', {
          detail: {
            rentalId: String(rentalId),
            favorite: isRentalInFavoritesList(rentalId),
          },
        })
      );
      rentalIdsToProcessQueue.current.delete(rentalId);
      processQueryAndNotify();
    }
  }, [favoritesList, isFavoritesListLoading]);

  const onRegisterRentalInFavoritesListener = useCallback(
    (e: CustomEvent<{ rentalId: string }>) => {
      rentalIdsToProcessQueue.current.add(e.detail.rentalId);
      processQueryAndNotify();
    },
    [processQueryAndNotify]
  );

  useEffect(() => {
    document.addEventListener(
      'rentalInFavoritesQuery',
      onRegisterRentalInFavoritesListener as EventListener
    );

    document.dispatchEvent(
      new CustomEvent('rentalInFavoritesQueriesReady', {})
    );

    return () =>
      document.removeEventListener(
        'rentalInFavoritesQuery',
        onRegisterRentalInFavoritesListener as EventListener
      );
  }, [onRegisterRentalInFavoritesListener]);

  return children;
};
