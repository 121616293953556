'use client';

import { isServer, QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 1 * 400, // 1 seconds of stale time in case of multiple query calls in a row, refetching // WARNING: this can lead to inconsistent result if you forget to add stale time to 0 on rapid queries. Cypress also will have issue while testing
        refetchOnWindowFocus: false, // do not refetch on window focus as default, can be overridden on a per-query basis
        retry: (failureCount, error) => {
          if (failureCount > 5) {
            return false;
          }

          if (error && isAxiosError(error)) {
            const status = error?.response?.status ?? 0;
            if ([401, 403, 404].includes(status)) {
              return false;
            }
          }

          return true;
        },
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
