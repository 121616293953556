'use client';

import { useCallback } from 'react';
import { useLanguage } from '@ae/i18n';
import { useGetDomain } from '@ae/shared';
import { useAuth } from '../providers/Auth';

export const useLogin = () => {
  const { keycloak } = useAuth();
  const { authDomain } = useGetDomain();
  const language = useLanguage();

  const login = useCallback(
    (redirectUrl?: string) => {
      const url = redirectUrl || window.location.href;
      window.location.href = `
      ${authDomain}/auth/login/${
        keycloak.id
      }/${language}?target=${encodeURIComponent(url)}`;
    },
    [authDomain, keycloak, language]
  );

  return { login };
};
