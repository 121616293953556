import { useCallback } from 'react';
import { format, isSameDay, lastDayOfMonth, startOfMonth } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  StayDateAvailableJsonldReadStayDateAvailableStayType,
  useApiLocalitiesGetCollection,
} from '@ae/data-access';
import { Locale, useLanguage } from '@ae/i18n';
import { useRouter } from '@ae/shared';
import {
  defaultDogsCount,
  defaultPersonsCount,
  defaultSearchValues,
  SearchValues,
  stayTypeLabelsByLanguage,
  stayTypesByLabel,
} from '../types';
import { Mr2Filters, useMr2Url } from './useMr2Url';

declare global {
  interface Window {
    AEStore: AEStore;
  }
}

type AEStore = {
  state: {
    mr1: {
      folder: {
        selectedFolderId: number | null;
      };
    };
    mr2: {
      filters: string[];
    };
  };
};

const dogRegexByLanguage: { [x in Locale]: RegExp } = {
  [Locale.Fr]: /(\d+)-(\d+)_Chiens/,
  [Locale.Nl]: /(\d+)-(\d+)_Honden/,
  [Locale.En]: /(\d+)-(\d+)_Dogs/,
  [Locale.De]: /(\d+)-(\d+)_Hunden/,
};

const dogLabelsByLanguage = {
  [Locale.Fr]: 'Chiens',
  [Locale.Nl]: 'Honden',
  [Locale.En]: 'Dogs',
  [Locale.De]: 'Hunden',
};

const folderUrlByLanguage = {
  [Locale.Fr]: '_maisons-de-vacances-en-Ardenne',
  [Locale.Nl]: '_vakantiehuizen-in-de-Ardennen',
  [Locale.En]: '_holiday-Houses-in-the-Ardennes',
  [Locale.De]: '_ferienwohnungen-in-den-Ardennen',
};

export const thematicUrlMatchers = {
  [Locale.Fr]: /\/maisons-de-vacances-ardennes\/?/,
  [Locale.Nl]: /\/vakantiehuizen-ardennen\/?/,
  [Locale.En]: /\/holiday-houses-ardennes\/?/,
  [Locale.De]: /\/ferienhauser-ardennen\/?/,
};

const stayTypeRegex: { [x in Locale]: RegExp } = {
  [Locale.Fr]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.Fr]).join('|')}`
  ),
  [Locale.Nl]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.Nl]).join('|')}`
  ),
  [Locale.En]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.En]).join('|')}`
  ),
  [Locale.De]: new RegExp(
    `${Object.values(stayTypeLabelsByLanguage[Locale.De]).join('|')}`
  ),
};

const generateDogsUrl = (dogs: number, language: Locale) => {
  const dogLabel = dogLabelsByLanguage[language];
  return `${dogs}-8_${dogLabel}`;
};

const parsePersons = (url: string) => {
  if (url.includes('1-99p')) {
    return null;
  }
  const regexPersons = /(\d+-\d+p|\d+p)/;
  const matchesPersons = url.match(regexPersons);
  if (matchesPersons && matchesPersons.length > 0) {
    const [people] = matchesPersons[0].split('p');
    const [minPeople] = people.split('-');
    return Number(minPeople);
  }
  return null;
};

const parseDogs = (url: string, language: Locale) => {
  const regexDogs = dogRegexByLanguage[language];
  const matchesDogs = url.match(regexDogs);
  if (matchesDogs) {
    const [, minDogs] = matchesDogs;
    return Number(minDogs);
  }
  return null;
};

export const isFullMonth = (startDate: Date, endDate: Date) =>
  isSameDay(startOfMonth(startDate), startDate) &&
  isSameDay(lastDayOfMonth(endDate), endDate);

export const useSearchUrl = () => {
  const language = useLanguage();
  const { pathname } = useRouter();
  const {
    parseMr2FiltersFromUrl,
    generateMr2FiltersUrl,
    parseMr2FiltersFromStringArray,
  } = useMr2Url();
  const { data: localities } = useApiLocalitiesGetCollection();

  // not used for now
  const parseSearchUrl = useCallback(
    (url?: string): SearchValues => {
      url = url ?? pathname;

      const searchParams: SearchValues = {
        ...defaultSearchValues,
      };

      // Parse compositions
      searchParams.composition = {
        persons: parsePersons(url) ?? 0,
        dogs: parseDogs(url, language as Locale) ?? 0,
      };

      let parsedStartDate = null;
      let parsedEndDate = null;
      let parsedStayType = null;

      // Parse dates
      const regexInterval = /(\d{4}-\d{2}-\d{2})_to_(\d{4}-\d{2}-\d{2})/;
      const matchesInterval = url.match(regexInterval);
      if (matchesInterval) {
        const [, startDate, endDate] = matchesInterval;
        parsedStartDate = toZonedTime(new Date(startDate), 'Europe/Brussels');
        parsedEndDate = toZonedTime(new Date(endDate), 'Europe/Brussels');
      } else {
        const regexDate = /(\d{4}-\d{2}-\d{2})/;
        const matchesDate = url.match(regexDate);
        if (matchesDate) {
          const [date] = matchesDate;
          if (date !== '0000-00-00') {
            parsedStartDate = startOfMonth(
              toZonedTime(
                new Date(date.replace(/-00$/, '-01')),
                'Europe/Brussels'
              )
            );
            parsedEndDate = lastDayOfMonth(parsedStartDate);
          }
        }
      }

      const matchesStayType = url.match(stayTypeRegex[language as Locale]);
      if (matchesStayType) {
        parsedStayType =
          stayTypesByLabel[language as Locale][matchesStayType[0]];
      }
      if (parsedStartDate || parsedEndDate || parsedStayType) {
        if (parsedStartDate && parsedEndDate) {
          parsedStartDate?.setHours(0, 0, 0, 0);
          parsedEndDate?.setHours(0, 0, 0, 0);
        }
        searchParams.stayDate = {
          startDate: parsedStartDate,
          endDate: parsedEndDate,
          stayType: parsedStayType,
        };
      }

      // Parse locality
      const regexLocalityId = new RegExp(
        `(\\d+)${folderUrlByLanguage[language as Locale]}`
      );
      const matchesLocalityId = url.match(regexLocalityId);
      if (matchesLocalityId) {
        searchParams.localityId = Number(matchesLocalityId[1]) ?? null;
      }

      return searchParams;
    },
    [language]
  );

  const generateSearchUrl = useCallback(
    (searchParams: SearchValues, lng?: Locale) => {
      lng = lng ?? (language as Locale);

      const urlParts = [];
      const composition =
        searchParams?.composition ??
        ({ persons: defaultPersonsCount, dogs: defaultDogsCount } as {
          persons: number;
          dogs: number;
        });
      const stayDate = searchParams?.stayDate;
      const localityId = searchParams?.localityId;

      const selectedFolderId =
        typeof window !== 'undefined' &&
        window?.AEStore?.state?.mr1?.folder?.selectedFolderId;
      const isDogThematic = selectedFolderId == 371;

      if (composition.persons === 0) {
        urlParts.push('1-99p');
      } else {
        urlParts.push(`${composition.persons}p`);
      }

      if (isDogThematic && composition.dogs === 0) {
        composition.dogs = 1;
      }

      if (composition.dogs !== 0) {
        urlParts.push(generateDogsUrl(composition.dogs, lng));
      }

      if (stayDate) {
        if (stayDate.stayType) {
          urlParts.push(stayTypeLabelsByLanguage[lng][stayDate.stayType]);
        }

        if (stayDate.startDate && stayDate.endDate) {
          const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

          if (isFullMonth(stayDate.startDate, stayDate.endDate)) {
            urlParts.push(
              formatDate(stayDate.startDate).replace(/-01$/, '-00')
            );
          } else {
            urlParts.push(
              `${formatDate(stayDate.startDate)}_to_${formatDate(
                stayDate.endDate
              )}`
            );
          }
        } else {
          urlParts.push('0000-00-00');
        }
      } else {
        const dateUrl =
          stayTypeLabelsByLanguage[lng][
            StayDateAvailableJsonldReadStayDateAvailableStayType.wk
          ];
        urlParts.push(dateUrl);
        urlParts.push('0000-00-00');
      }

      if (
        localityId &&
        localities?.['hydra:member'].find((l) => l.id === localityId)
      ) {
        const folderUrl = folderUrlByLanguage[lng];
        urlParts.push(`${localityId}${folderUrl}`);
      } else if (selectedFolderId) {
        if (isDogThematic && composition.dogs === 0) {
          // Bypass folder
        } else {
          const folderUrl = folderUrlByLanguage[lng];
          urlParts.push(`${selectedFolderId}${folderUrl}`);
        }
      }

      if (
        typeof window !== 'undefined' &&
        thematicUrlMatchers[lng].test(window.location.href)
      ) {
        // We parse mr2 filters from the old system for now.
        const mr2Filters = parseMr2FiltersFromUrl();
        const legacyMr2Filters = window?.AEStore?.state?.mr2?.filters
          ? parseMr2FiltersFromStringArray(window.AEStore.state.mr2.filters)
          : {};

        if (Mr2Filters.TriRecherche in mr2Filters) {
          legacyMr2Filters[Mr2Filters.TriRecherche] =
            mr2Filters[Mr2Filters.TriRecherche];
        }

        const mr2UrlPart = generateMr2FiltersUrl(legacyMr2Filters);
        if (mr2UrlPart) {
          urlParts.push(mr2UrlPart);
        }
      } else {
        // Mr2 filters
        const mr2Filters = generateMr2FiltersUrl(parseMr2FiltersFromUrl(), lng);
        if (mr2Filters) {
          urlParts.push(mr2Filters);
        }
      }

      return '/' + urlParts.join('/');
    },
    [
      language,
      pathname,
      parseMr2FiltersFromUrl,
      generateMr2FiltersUrl,
      parseMr2FiltersFromStringArray,
    ]
  );

  return {
    parseSearchUrl,
    generateSearchUrl,
  };
};
