import NextLink from 'next/link';
import React, { ReactElement } from 'react';
import { useRouter } from '@ae/shared';

type Props = {
  href: string;
  target?: string;
  children: ReactElement;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

export const LinkWrapper = ({ href, onClick, target, children }: Props) => {
  const { linkLegacyBehavior } = useRouter();
  // NOTE: There is a bug in the way nextlink handle absolute url when we are using a basePath on prod server
  // See issue : https://github.com/vercel/next.js/issues/8555}
  const isAbsolute = typeof href === 'string' && href?.startsWith('http');

  if (
    isAbsolute ||
    linkLegacyBehavior ||
    target === '_blank' ||
    href.endsWith('#') // Do not use potential achor links with next link as it could break with RSC payload issue
  ) {
    return React.cloneElement(children, { href, target });
  }

  return (
    // https://nextjs.org/docs/app/building-your-application/upgrading/codemods#remove-a-tags-from-link-components
    <NextLink href={href} passHref legacyBehavior onClick={onClick}>
      {children}
    </NextLink>
  );
};
