import { useChangeLocale } from '@ae/shared-comp';
import {
  LanguageMenu as BaseLanguageMenu,
  LanguageMenuSelectorStyle,
} from '@ae/shared-ui';

const LanguageMenu = () => {
  const { changeLocale } = useChangeLocale();

  return (
    <BaseLanguageMenu
      selectorStyle={LanguageMenuSelectorStyle.Button}
      onChangeLocale={changeLocale}
      ga4={{
        website_subsection: 'footer',
      }}
    />
  );
};
export default LanguageMenu;
