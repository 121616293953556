'use client';

import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { useLanguage } from '@ae/i18n';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

require('dayjs/locale/fr');
require(`dayjs/locale/nl`);
require(`dayjs/locale/en`);
require(`dayjs/locale/de`);

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const useDate = () => {
  const language = useLanguage();

  const formatDate = useCallback(
    (date: string | Date, separator: string = '.'): string =>
      dayjs(date)
        .locale(language)
        .tz('Europe/Brussels')
        .format('L')
        .replace(/\//g, separator),
    [language]
  );

  const formatDateCustom = useCallback(
    (date: string | Date, format = 'L'): string =>
      dayjs(date).locale(language).tz('Europe/Brussels').format(format),
    [language]
  );

  // current date in ISO8601, without fraction seconds e.g. '2020-04-02T08:02:17-05:00'
  const formatDateIso = useCallback(
    (date: string | Date): string =>
      dayjs(date).locale(language).tz('Europe/Brussels').format(),
    [language]
  );

  const formatDateWithoutYear = useCallback(
    (date: string | Date, separator: string = '.'): string =>
      formatDate(date)
        .replace(/(\.|-)\d{4}/, '')
        .replace(/\./g, separator),
    [formatDate]
  );

  useEffect(() => {
    // set local globally
    dayjs.locale(language);
  }, [language]);

  return {
    formatDate,
    formatDateWithoutYear,
    formatDateIso,
    formatDateCustom,
    dayjs,
  };
};
