import { useMemo, useState } from 'react';
import { lastDayOfMonth, startOfMonth } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  LocalityJsonldReadLocality,
  StayDateAvailableJsonldReadStayDateAvailableStayType,
} from '@ae/data-access';
import { Locale, useLanguage } from '@ae/i18n';
import { AELink, useGetAELink, useRouter } from '@ae/shared';
import { useTag } from '@ae/tagging';
import { SearchValues } from '../types';
import { useContextualDataLayer } from './useContextualDataLayer';
import { useLocalityInCookie } from './useLocalityInCookie';
import { thematicUrlMatchers, useSearchUrl } from './useSearchUrl';
import { useSearchValuesInCookie } from './useSearchValuesInCookie';

// Map of search URL patterns by locale
const SEARCH_URL_MATCHERS: Record<Locale, string> = {
  [Locale.Fr]: '/recherche',
  [Locale.Nl]: '/zoeken',
  [Locale.En]: '/suchen',
  [Locale.De]: '/search',
};

// Campaign parameter for early booking
const EARLY_BOOKING_CAMPAIGN = 'nc-bimensuelle-2-202411-conversion';

/**
 * Custom hook to manage search functionality
 * Handles search values, URL parsing, and search execution
 */
export const useSearch = ({
  localities,
}: {
  localities: LocalityJsonldReadLocality[] | null | undefined;
}) => {
  const { pathname } = useRouter();
  const { getLinkHref } = useGetAELink();
  const trackEvent = useTag();
  const language = useLanguage();
  const { updateDataLayer, getDataLayer } = useContextualDataLayer();
  const { setLocality: setLocalityInCookie } = useLocalityInCookie();
  const { generateSearchUrl, parseSearchUrl } = useSearchUrl();
  const {
    searchValues: searchValuesFromCookie,
    setSearchValues: setSearchValuesIntoCookie,
  } = useSearchValuesInCookie();

  // Check if current URL is a search page
  const isSearchPage = useMemo(() => {
    return pathname?.includes(SEARCH_URL_MATCHERS[language as Locale] || '');
  }, [pathname, language]);

  // Check if current URL has early booking campaign parameter
  const isEarlyBookingCampaign = useMemo(() => {
    if (typeof window === 'undefined') return false;
    const urlParams = new URLSearchParams(
      window.location.href.split('?')[1] || ''
    );
    return urlParams.get('utm_campaign') === EARLY_BOOKING_CAMPAIGN;
  }, []);

  // Check if current URL is a thematic page
  const isThematicPage = useMemo(() => {
    return pathname?.match(thematicUrlMatchers[language as Locale] || '');
  }, [pathname, language]);

  // Initialize search values
  const [searchValues, setSearchValues] = useState<SearchValues>(() => {
    // If we're on a search page, parse values from URL
    if (isSearchPage) {
      const parsedValues = parseSearchUrl();
      setSearchValuesIntoCookie(parsedValues);
      return parsedValues;
    }

    // Special case for early booking campaign
    if (isEarlyBookingCampaign) {
      const startDate = toZonedTime(
        new Date('2025-01-01'),
        'Europe/Brussels'
      ).setHours(0, 0, 0, 0);

      return {
        ...searchValuesFromCookie,
        stayDate: {
          startDate: startOfMonth(startDate),
          endDate: lastDayOfMonth(startDate),
          stayType: StayDateAvailableJsonldReadStayDateAvailableStayType.wk,
        },
      };
    }

    // Special case for thematic pages
    if (isThematicPage) {
      const selectedFolderId =
        window?.AEStore?.state?.mr1?.folder?.selectedFolderId;

      return {
        ...searchValuesFromCookie,
        localityId: selectedFolderId || searchValuesFromCookie.localityId,
      };
    }

    // Default case: use values from cookie
    return { ...searchValuesFromCookie };
  });

  /**
   * Updates search values and related state
   * If locality changed, updates locality cookie
   */
  const updateSearch = (newSearch: SearchValues) => {
    // Handle locality change
    if (localities && newSearch.localityId !== searchValues.localityId) {
      if (newSearch.localityId) {
        const locality = localities.find((l) => l.id === newSearch.localityId);
        if (locality) {
          setLocalityInCookie(locality);
        }
      } else {
        setLocalityInCookie(null);
      }
    }

    // Update state and data layer
    setSearchValues(newSearch);
    updateDataLayer(newSearch);
  };

  /**
   * Executes the search: tracks event, saves to cookie, navigates to search page
   */
  const makeSearch = () => {
    // Track search event
    trackEvent({
      ga4: {
        event: 'search',
        action: 'submit',
        filter_level: 'mr1',
        ...getDataLayer(searchValues),
      },
    });

    // Save search values to cookie
    setSearchValuesIntoCookie(searchValues);

    // Generate search URL
    const searchUrl = `${getLinkHref(AELink.Search)}${generateSearchUrl(searchValues)}`;

    // Navigate to search page
    window.location.href = searchUrl;
  };

  return {
    searchValues,
    updateSearch,
    makeSearch,
  };
};
