'use client';

import React from 'react';
import {
  Box,
  BoxProps,
  Container,
  Grid,
  GridItem,
  HStack,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { SeparatorProps, UISeparator, useViewport } from '@ae/shared-ui';
import AESection from '../components/AESection';
import FollowUsSection from '../components/FollowUsSection';
import HelpcenterSection from '../components/HelpcenterSection';
import HostSection from '../components/HostSection';
import InfoSection from '../components/InfoSection';
import LanguageMenu from '../components/LanguageMenu';
import SupportSection from '../components/SupportSection';

type Props = {
  hideHelpCenter?: boolean;
  topComponent?: React.ReactNode;
};

const FooterSeparator = (props: SeparatorProps) => (
  <UISeparator color="ae.whiteAlpha20" {...props} />
);

export const Footer = ({
  hideHelpCenter = false,
  topComponent = null,
  ...props
}: Props & BoxProps) => {
  const { Mobile, Tablet, Desktop } = useViewport();

  return (
    <>
      {topComponent && (
        <Box position="relative" mt="50px">
          <Box
            bgColor="ae.green"
            position="absolute"
            top={{ base: '400px', md: '50px' }}
            bottom={0}
            w="100%"
          />
          <Container position="relative" maxW="container.xl">
            {topComponent}
          </Container>
        </Box>
      )}
      <Box data-testid="footer" id="ae-footer">
        {!hideHelpCenter && <HelpcenterSection />}
        <Mobile>
          <Box py="30px" px="23px" bgColor="ae.green" w="100%" {...props}>
            <VStack
              spacing="30px"
              divider={<FooterSeparator />}
              alignItems="stretch"
            >
              <FollowUsSection />
              <AESection />
              <HostSection />
              <SupportSection />
              <LanguageMenu />
              <InfoSection />
            </VStack>
          </Box>
        </Mobile>
        <Tablet>
          <Box pt="70px" pb="60px" bgColor="ae.green" w="100%" {...props}>
            <Container maxW="container.xl">
              <Grid templateColumns="repeat(2, auto)" columnGap="20px">
                <GridItem>
                  <AESection />
                </GridItem>
                <GridItem>
                  <HostSection />
                </GridItem>

                <GridItem mt="40px">
                  <FollowUsSection />
                </GridItem>
                <GridItem mt="40px">
                  <SupportSection />
                </GridItem>
              </Grid>

              <FooterSeparator my="40px" />
              <VStack
                spacing="30px"
                divider={<FooterSeparator />}
                alignItems="stretch"
              >
                <LanguageMenu />
                <InfoSection />
              </VStack>
            </Container>
          </Box>
        </Tablet>
        <Desktop>
          <Box pt="80px" pb="60px" bgColor="ae.green" w="100%" {...props}>
            <Container maxW="container.xl">
              <Grid
                templateColumns="repeat(4, auto)"
                columnGap={{ base: '5%', lg: '71px' }}
              >
                <GridItem>
                  <AESection />
                </GridItem>
                <GridItem>
                  <HostSection />
                </GridItem>
                <GridItem>
                  <SupportSection />
                </GridItem>
                <GridItem>
                  <FollowUsSection />
                </GridItem>
              </Grid>

              <FooterSeparator my="40px" />

              <HStack>
                <InfoSection />
                <Spacer />
                <LanguageMenu />
              </HStack>
            </Container>
          </Box>
        </Desktop>
      </Box>
    </>
  );
};
