import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  CustomerStepReadCustomerStepImportance,
  CustomerStepReadCustomerStepStatus,
  CustomerStepReadCustomerStepType,
  getApiCustomerStepsGetCollectionQueryKey,
  useApiCustomerStepsGetCollection,
} from '@ae/data-access';
import { useMe } from './useMe';

export const useInvalidateNotificationBullets = () => {
  const queryClient = useQueryClient();

  const invalidateNotificationBullets = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: getApiCustomerStepsGetCollectionQueryKey(),
    });
  }, [queryClient]);

  return { invalidateNotificationBullets };
};

export const useNotificationBullets = () => {
  const { authenticated } = useMe();
  const { data: customerStepCollection } = useApiCustomerStepsGetCollection({
    query: { enabled: authenticated },
  });
  const customerSteps = customerStepCollection?.['hydra:member'];

  const hasProfileNotification =
    customerSteps &&
    customerSteps.some(
      (step) =>
        step.type ===
          CustomerStepReadCustomerStepType.BOOKING_CUSTOMER_BANK_DETAIL_REQUIRED &&
        step.status !== CustomerStepReadCustomerStepStatus.DONE
    );

  const hasBookingNotification =
    customerSteps &&
    customerSteps.some(
      (step) =>
        step.booking &&
        step.status !== CustomerStepReadCustomerStepStatus.DONE &&
        step.importance === CustomerStepReadCustomerStepImportance.HIGH
    );

  const hasAnyNotifications =
    hasBookingNotification ?? hasProfileNotification ?? false;

  return {
    hasProfileNotification,
    hasBookingNotification,
    hasAnyNotifications,
  };
};
