'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

export const useCurrentDomain = () => {
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [currentDomain, setCurrentDomain] = useState<string>('');
  const pathname = usePathname();

  // on page load and pathname change, we update the current url and domain
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentUrl(window.location.href);
      setCurrentDomain(window.location.origin);
    }
  }, [pathname]);

  return { currentDomain, currentUrl, pathname };
};
