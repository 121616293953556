'use client';

import { createContext, ReactNode, useContext } from 'react';
import { ExperimentGroup, ExperimentSelector } from './config';
import { ExperimentContext } from './Experiment';

interface ExperimentSelectionContextType {
  experimentSelector: ExperimentSelector;
  groupSelector: ExperimentGroup;
}

const ExperimentSelectionContext =
  createContext<ExperimentSelectionContextType>(
    {} as ExperimentSelectionContextType
  );

export const useExperiment = (): {
  experimentId: string | null;
  experimentGroup: ExperimentGroup | null;
  variantId: string | null;
} => {
  const { groupSelections, experimentConfigurations } =
    useContext(ExperimentContext);
  const { experimentSelector } = useContext(ExperimentSelectionContext);

  if (experimentSelector === undefined) {
    return {
      experimentId: null,
      experimentGroup: null,
      variantId: null,
    };
  }

  return {
    experimentId: experimentConfigurations[experimentSelector].experimentId,
    experimentGroup: groupSelections[experimentSelector],
    variantId: String(groupSelections[experimentSelector] as number),
  };
};

export const Experiment = ({
  children,
  experimentSelector,
  groupSelector,
}: {
  children: ReactNode;
  experimentSelector: ExperimentSelector;
  groupSelector: ExperimentGroup;
}) => {
  const { groupSelections } = useContext(ExperimentContext);

  return (
    <ExperimentSelectionContext.Provider
      value={{ experimentSelector, groupSelector }}
    >
      {groupSelections[experimentSelector] === groupSelector && children}
    </ExperimentSelectionContext.Provider>
  );
};
