import { useCallback, useMemo } from 'react';
import { LocalityJsonldReadLocality } from '@ae/data-access';
import { useCrossDomainCookie, useGetDomain } from '@ae/shared';

const COOKIE_NAME = 'mr1.locality';

export const useLocalityInCookie = () => {
  const { getCookie, setCookie } = useCrossDomainCookie();
  const { prodDomain, getRacineDomain } = useGetDomain();
  const domain = getRacineDomain(prodDomain);

  const locality: LocalityJsonldReadLocality | null | undefined =
    useMemo(() => {
      try {
        const cookie = getCookie(COOKIE_NAME);
        if (!cookie) {
          return null;
        }
        return cookie ? JSON.parse(cookie) : null;
      } catch (e) {
        return null;
      }
    }, [getCookie]);

  const setLocality = useCallback(
    (locality: LocalityJsonldReadLocality | null | undefined) => {
      setCookie(
        COOKIE_NAME,
        locality ? JSON.stringify(locality) : null,
        domain,
        locality ? null : new Date(0)
      );
    },
    [setCookie]
  );

  return { locality, setLocality };
};
