/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Ardennes-étape
 * OpenAPI spec version: 1.0.0
 */

export type CustomerStepReadCustomerStepType =
  (typeof CustomerStepReadCustomerStepType)[keyof typeof CustomerStepReadCustomerStepType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerStepReadCustomerStepType = {
  BOOKING_CUSTOMER_HAS_MULTIPLE_BOOKING:
    'BOOKING_CUSTOMER_HAS_MULTIPLE_BOOKING',
  BOOKING_CUSTOMER_IN_RED_LIST: 'BOOKING_CUSTOMER_IN_RED_LIST',
  BOOKING_CUSTOMER_PREVIOUSLY_DID_NOT_PAY:
    'BOOKING_CUSTOMER_PREVIOUSLY_DID_NOT_PAY',
  BOOKING_PURPOSE_NEEDED: 'BOOKING_PURPOSE_NEEDED',
  BOOKING_OWNER_CONFIRMATION: 'BOOKING_OWNER_CONFIRMATION',
  BOOKING_DOWN_PAYMENT: 'BOOKING_DOWN_PAYMENT',
  BOOKING_BALANCE_PAYMENT: 'BOOKING_BALANCE_PAYMENT',
  BOOKING_CUSTOMER_HAVE_TO_TELL_ARRIVAL_HOURS_TO_OWNER:
    'BOOKING_CUSTOMER_HAVE_TO_TELL_ARRIVAL_HOURS_TO_OWNER',
  BOOKING_CUSTOMER_CAN_DOWNLOAD_ACTIVITY_PASS:
    'BOOKING_CUSTOMER_CAN_DOWNLOAD_ACTIVITY_PASS',
  BOOKING_CUSTOMER_CAN_LEAVE_A_REVIEW: 'BOOKING_CUSTOMER_CAN_LEAVE_A_REVIEW',
  BOOKING_CANCELLED_OWNER_PLANNING: 'BOOKING_CANCELLED_OWNER_PLANNING',
  BOOKING_CANCELLED_BY_CUSTOMER: 'BOOKING_CANCELLED_BY_CUSTOMER',
  BOOKING_CANCELLED_BY_OWNER: 'BOOKING_CANCELLED_BY_OWNER',
  BOOKING_CANCELLED_GROUP_NOT_COMPLIANT:
    'BOOKING_CANCELLED_GROUP_NOT_COMPLIANT',
  BOOKING_CANCELLED_MUTUAL_AGREEMENT: 'BOOKING_CANCELLED_MUTUAL_AGREEMENT',
  BOOKING_CANCELLED_DUE_TO_COVID_WAITING_RELOCATION:
    'BOOKING_CANCELLED_DUE_TO_COVID_WAITING_RELOCATION',
  BOOKING_CANCELLED_DUE_TO_COVID: 'BOOKING_CANCELLED_DUE_TO_COVID',
  BOOKING_CANCELLED_DUE_TO_COVID_HOW_TO_USE_VOUCHER:
    'BOOKING_CANCELLED_DUE_TO_COVID_HOW_TO_USE_VOUCHER',
  BOOKING_CANCELLED_FOR_NON_PAYMENT: 'BOOKING_CANCELLED_FOR_NON_PAYMENT',
  BOOKING_ALL_ADVANTAGES_AVAILABLE: 'BOOKING_ALL_ADVANTAGES_AVAILABLE',
  BOOKING_ACTIVITY_NEAR_HOUSE: 'BOOKING_ACTIVITY_NEAR_HOUSE',
  BOOKING_ORGANIZE_YOUR_STAY: 'BOOKING_ORGANIZE_YOUR_STAY',
  BOOKING_CUSTOMER_BANK_DETAIL_REQUIRED:
    'BOOKING_CUSTOMER_BANK_DETAIL_REQUIRED',
  NO_UPCOMING_BOOKING: 'NO_UPCOMING_BOOKING',
  SUBSCRIBE_TO_NEWSLETTER: 'SUBSCRIBE_TO_NEWSLETTER',
  BOOKING_GUESTS_FOR_INSURANCE: 'BOOKING_GUESTS_FOR_INSURANCE',
} as const;
