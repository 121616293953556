import {
  addMonths,
  isBefore,
  isSameMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { StayDateAvailableJsonldReadStayDateAvailable } from '@ae/data-access';
import { StayDate } from '@ae/shared-comp';
import { ParsedStayDates } from './types';

type StayDateAvailable = StayDateAvailableJsonldReadStayDateAvailable;

const mapStayDates = (
  stayDates: StayDateAvailable[] | undefined
): StayDate[] => {
  return (
    stayDates?.map(
      (stayDate: any) =>
        ({
          id: stayDate.id,
          startDate: parseISO(stayDate.date.startDate),
          endDate: parseISO(stayDate.date.endDate),
          stayType: stayDate.stayType,
        }) as StayDate
    ) ?? []
  );
};

export const parseStayDates = (
  stayDatesAvailable: StayDateAvailableJsonldReadStayDateAvailable[] | null
): ParsedStayDates => {
  const initialValue: ParsedStayDates = {
    stayDates: {
      wk: {},
      lw: {},
      mw: {},
      '1w': {},
      '2w': {},
      custom: {},
    },
    oldestStayDate: null,
    newestStayDate: null,
    months: [],
  };

  if (!stayDatesAvailable) return initialValue;

  const stayDates = mapStayDates(stayDatesAvailable);

  const parsedStayDates = stayDates.reduce((acc, stay) => {
    if (!stay || !stay.stayType || !stay.startDate) return acc;

    const { stayType, startDate, endDate } = stay;
    const date = new Date(startDate);
    const year = date.getFullYear();
    const month = date.getMonth();

    if (!acc.stayDates[stayType]) {
      acc.stayDates[stayType] = {};
    }

    if (!acc.stayDates[stayType][year]) {
      acc.stayDates[stayType][year] = {};
    }

    if (!acc.stayDates[stayType][year][month]) {
      acc.stayDates[stayType][year][month] = [];
    }

    acc.stayDates[stayType][year][month].push(stay);

    if (endDate) {
      const endDateDate = new Date(endDate);
      const endDateYear = endDateDate.getFullYear();
      const endDateMonth = endDateDate.getMonth();

      if (month !== endDateMonth) {
        if (!acc.stayDates[stayType][endDateYear]) {
          acc.stayDates[stayType][endDateYear] = {};
        }

        if (!acc.stayDates[stayType][endDateYear][endDateMonth]) {
          acc.stayDates[stayType][endDateYear][endDateMonth] = [];
        }

        acc.stayDates[stayType][endDateYear][endDateMonth].push(stay);
      }
    }

    // Update oldestStayDate
    if (
      !acc.oldestStayDate ||
      new Date(startDate) < new Date(acc.oldestStayDate.startDate ?? new Date())
    ) {
      acc.oldestStayDate = stay;
    }

    // Update newestStayDate
    if (
      !acc.newestStayDate ||
      new Date(endDate! ?? new Date()) >
        new Date(acc.newestStayDate.endDate ?? new Date())
    ) {
      acc.newestStayDate = stay;
    }

    return acc;
  }, initialValue);

  // Generate months between oldest and newest stay dates
  if (parsedStayDates.oldestStayDate && parsedStayDates.newestStayDate) {
    const startDate = new Date(
      parsedStayDates.oldestStayDate.startDate ?? new Date()
    );
    const endDate = new Date(
      parsedStayDates.newestStayDate.endDate ?? new Date()
    );
    let currentDate = startOfMonth(startDate);

    while (
      isBefore(currentDate, endDate) ||
      isSameMonth(currentDate, endDate)
    ) {
      parsedStayDates.months.push(currentDate);
      currentDate = addMonths(currentDate, 1);
    }
  }

  return parsedStayDates;
};
