import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  addMonths,
  isSameDay,
  isSameMonth,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import {
  StayDateAvailableJsonldReadStayDateAvailable,
  StayDateAvailableJsonldReadStayDateAvailableStayType,
} from '@ae/data-access';
import { Locale, useLanguage } from '@ae/i18n';
import { ChevronLeftIcon, ChevronRightIcon } from '@ae/icons';
import {
  capitalizeFirstLetter,
  formatToBrusselsTz,
  useCalendar,
  useTranslation,
} from '@ae/shared';
import { StayDate, stayTypeLabels } from '@ae/shared-comp';
import {
  UIButton,
  UIButtonProps,
  UISelectBox,
  useViewport,
} from '@ae/shared-ui';
import { CalendarDay } from './CalendarDay';
import {
  CalendarStayDatesProvider,
  useMonths,
  useNewestStayDate,
  useOldestStayDate,
  useSelectedStayDate,
  useSelectedStayType,
  useSetSelectedStayDate,
  useSetSelectedStayType,
} from './stores';

export type CalendarPickerProps = {
  value: StayDate | null;
  onChange: (date: StayDate | null) => void;
  bottomComponent?: React.ReactNode;
  focusCalendarPopover: () => void;
};

const InnerCalendarPicker = ({
  value,
  onChange: onPropsChange,
  bottomComponent,
  focusCalendarPopover,
}: CalendarPickerProps) => {
  const { t } = useTranslation('mr');
  const locale = useLanguage();
  const { MobileAndTablet, Desktop } = useViewport();

  const [selectedMonth, setSelectedMonth] = useState<Date>(
    value?.startDate ?? new Date()
  );

  const { getMonthlyDates, getWeekDayNames } = useCalendar();
  const setSelectedStayType = useSetSelectedStayType();
  const setSelectedStayDate = useSetSelectedStayDate();
  const selectedStayDate = useSelectedStayDate();
  const selectedStayType = useSelectedStayType();
  const oldestStayDate = useOldestStayDate();
  const newestStayDate = useNewestStayDate();

  const onChange = (date: StayDate | null) => {
    setSelectedStayDate(date);
    onPropsChange(date);
  };

  useEffect(() => {
    if (value?.stayType && selectedStayType !== value?.stayType) {
      setSelectedStayType(value.stayType);
    }
    if (selectedStayDate !== value) {
      setSelectedStayDate(value);
    }
  }, [value]);

  const changeStayType = (
    stayType: StayDateAvailableJsonldReadStayDateAvailableStayType
  ) => {
    onChange({
      startDate: null,
      endDate: null,
      stayType,
    });
    setSelectedStayType(stayType);
  };

  const onMonthClick = (month: Date | null) => {
    if (!month) {
      onChange({
        startDate: null,
        endDate: null,
        stayType: selectedStayType,
      });
      return;
    }
    onChange({
      startDate: startOfMonth(month),
      endDate: lastDayOfMonth(month),
      stayType: selectedStayType,
    });
  };

  const getHeaderOfCalendar = (
    selectedDateToDisplay: Date,
    displaySelectors: boolean
  ) => {
    const PreviousNextMonthButton = ({
      dataTestId,
      icon,
      ...props
    }: { dataTestId: string; icon: React.ReactNode } & UIButtonProps) => {
      return (
        <UIButton
          data-testid={dataTestId}
          bgColor="ae.grey_100"
          h="26px"
          w="26px"
          p={0}
          minW={0}
          borderRadius="22px"
          lineHeight="22px"
          {...props}
        >
          {icon}
        </UIButton>
      );
    };

    const isCurrentMonthSelected =
      (value &&
        value.startDate &&
        value.endDate &&
        isSameDay(startOfMonth(selectedDateToDisplay), value?.startDate) &&
        isSameDay(lastDayOfMonth(selectedDateToDisplay), value?.endDate)) ??
      false;

    return (
      <Flex justifyContent="space-between" h="26px">
        <UIButton
          size="sm"
          variant={isCurrentMonthSelected ? 'secondary' : 'linkUnderlined'}
          ml="6px"
          w="inherit"
          onClick={() =>
            onMonthClick(isCurrentMonthSelected ? null : selectedDateToDisplay)
          }
        >
          {capitalizeFirstLetter(
            formatToBrusselsTz(
              selectedDateToDisplay,
              'LLLL yyy',
              locale as Locale
            )
          )}
        </UIButton>

        {displaySelectors && (
          <HStack>
            <PreviousNextMonthButton
              dataTestId="calendar-picker-previous-month"
              onClick={() => setSelectedMonth(addMonths(selectedMonth, -1))}
              icon={<ChevronLeftIcon size="10px" />}
              disabled={
                oldestStayDate?.startDate
                  ? isSameMonth(
                      selectedMonth,
                      new Date(oldestStayDate.startDate)
                    )
                  : false
              }
            />
            <PreviousNextMonthButton
              dataTestId="calendar-picker-next-month"
              onClick={() => setSelectedMonth(addMonths(selectedMonth, 1))}
              icon={<ChevronRightIcon size="10px" />}
              disabled={
                newestStayDate?.endDate
                  ? isSameMonth(
                      selectedMonth,
                      new Date(newestStayDate?.endDate)
                    )
                  : false
              }
            />
          </HStack>
        )}
      </Flex>
    );
  };

  const getWeekDaysNamesOfCalendar = () => {
    return (
      <Grid mt="22px" mb="10px" gridTemplateColumns="repeat(7, 50px)">
        {getWeekDayNames(3).map((weekDayName, i) => (
          <GridItem key={i}>
            <Center
              color={i === 5 || i === 6 ? 'ae.grey_300' : 'ae.grey_400'}
              fontSize="14px"
            >
              <Text>{weekDayName}</Text>
            </Center>
          </GridItem>
        ))}
      </Grid>
    );
  };

  const getDatesOfCalendar = (selectedMonth: Date) => {
    return (
      <Grid
        gridTemplateColumns="repeat(7, 50px)"
        gridTemplateRows="repeat(5, 38px)"
        rowGap="6px"
      >
        {getMonthlyDates(selectedMonth).map((week) =>
          week.map((day, i) => (
            <CalendarDay
              key={i}
              day={day}
              selectedMonth={selectedMonth}
              onClick={onChange}
              focusCalendarPopover={focusCalendarPopover}
            />
          ))
        )}
      </Grid>
    );
  };

  const MonthSelect = ({
    w,
    mb,
  }: {
    w?: number | string;
    mb?: number | string;
  }) => {
    const months = useMonths();

    const options = months
      .map((month) => {
        return {
          value: month,
          label: formatToBrusselsTz(month, 'LLLL yyy', locale as Locale),
          year: formatToBrusselsTz(month, 'yyyy', locale as Locale),
        };
      })
      .filter((v, i, arr) => arr.findIndex((t) => t.label === v.label) === i)
      .sort((a, b) => a.value.getTime() - b.value.getTime());

    const groupedOptions = options.reduce(
      (acc: { [key: string]: typeof options }, option) => {
        if (!acc[option.year]) {
          acc[option.year] = [];
        }
        acc[option.year].push(option);
        return acc;
      },
      {}
    );

    return (
      <UISelectBox
        dataTestId="calendar-picker-month-select"
        aria-label={t('mr1.date.month_selection_aria')}
        w={w}
        mb={mb}
        value={selectedMonth.toISOString()}
        onChange={(e) => setSelectedMonth(parseISO(e.target.value))}
      >
        {Object.keys(groupedOptions).map((year) => (
          <optgroup key={year} label={year}>
            {groupedOptions[year].map(({ value, label }) => (
              <option key={label} value={value.toISOString()}>
                {capitalizeFirstLetter(label)}
              </option>
            ))}
          </optgroup>
        ))}
      </UISelectBox>
    );
  };

  const StayTypeButtons = () => {
    const StayTypeButton = ({
      label,
      type,
    }: {
      label: string;
      type: StayDateAvailableJsonldReadStayDateAvailableStayType;
    }) => (
      <UIButton
        variant={selectedStayType === type ? 'secondary' : 'tertiary'}
        size="sm"
        label={label}
        w="auto"
        onClick={() => changeStayType(type)}
      />
    );

    return (
      <HStack gap="10px">
        {Object.keys(StayDateAvailableJsonldReadStayDateAvailableStayType)
          .filter(
            (key) =>
              key !==
              StayDateAvailableJsonldReadStayDateAvailableStayType.custom
          )
          .map((key) => (
            <StayTypeButton
              key={key}
              label={t(
                stayTypeLabels[
                  key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                ]
              )}
              type={
                StayDateAvailableJsonldReadStayDateAvailableStayType[
                  key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                ]
              }
            />
          ))}
      </HStack>
    );
  };

  const Legend = () => (
    <HStack spacing="20px" mb="20px">
      <HStack>
        <Center
          h="18px"
          w="18px"
          borderRadius="18px"
          boxShadow="0 2px 5px rgba(0,0,0,.15)"
        >
          <Text color="ae.green" fontSize="12px">
            2
          </Text>
        </Center>
        <Text fontSize="12px">{t('mr1.arrival_day')}</Text>
      </HStack>
      <HStack>
        <Box h="10px" w="20px" borderRadius="20px" bgColor="ae.green" />
        <Text fontSize="12px">{t('mr1.chosen_stays')}</Text>
      </HStack>
      <HStack>
        <Box h="10px" w="20px" borderRadius="20px" bgColor="ae.pink" />
        <Text fontSize="12px">{t('mr1.available_stays')}</Text>
      </HStack>
    </HStack>
  );

  return (
    <Box data-testid="calendar-picker">
      <MobileAndTablet>
        <UISelectBox
          aria-label={t('mr1.date.staytype_selection_aria')}
          onChange={(e) =>
            changeStayType(
              e.target
                .value as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
            )
          }
        >
          {Object.keys(StayDateAvailableJsonldReadStayDateAvailableStayType)
            .filter(
              (key) =>
                key !==
                StayDateAvailableJsonldReadStayDateAvailableStayType.custom
            )
            .map((key) => (
              <option key={key} value={key}>
                {t(
                  stayTypeLabels[
                    key as keyof typeof StayDateAvailableJsonldReadStayDateAvailableStayType
                  ]
                )}
              </option>
            ))}
        </UISelectBox>

        <MonthSelect mb="20px" />

        {getHeaderOfCalendar(selectedMonth, true)}

        <VStack mb="20px">
          {getWeekDaysNamesOfCalendar()}
          {getDatesOfCalendar(selectedMonth)}
        </VStack>

        <Legend />
        {bottomComponent}
      </MobileAndTablet>

      <Desktop>
        <Box w="900px">
          <Flex justifyContent="space-between" mb="36px">
            <StayTypeButtons />
            <MonthSelect w="200px" mb={0} />
          </Flex>

          <Grid templateColumns="repeat(2, 1fr)" gap="20px" mb="24px">
            <GridItem>
              {getHeaderOfCalendar(selectedMonth, false)}
              {getWeekDaysNamesOfCalendar()}
              {getDatesOfCalendar(selectedMonth)}
            </GridItem>
            <GridItem>
              {getHeaderOfCalendar(addMonths(selectedMonth, 1), true)}
              {getWeekDaysNamesOfCalendar()}
              {getDatesOfCalendar(addMonths(selectedMonth, 1))}
            </GridItem>
          </Grid>

          <Legend />
          {bottomComponent}
        </Box>
      </Desktop>
    </Box>
  );
};

export const CalendarPicker = ({
  stayDates,
  ...props
}: {
  stayDates: StayDateAvailableJsonldReadStayDateAvailable[];
} & CalendarPickerProps) => {
  if (!stayDates.length && process.env['STORYBOOK_TEST_VT'] !== '1') {
    return <Spinner />;
  }

  return (
    <CalendarStayDatesProvider stayDates={stayDates}>
      <InnerCalendarPicker {...props} />
    </CalendarStayDatesProvider>
  );
};
