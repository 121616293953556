import { useContext } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import {
  getLanguagePartFromLocale,
  LanguageContext,
  Locale,
  useLanguage,
} from '@ae/i18n';
import { useExperiment, useTranslation } from '@ae/shared';
import { UIButton } from '@ae/shared-ui';
import { TagElement } from '@ae/tagging';

type Props = {
  menuStyle?: boolean;
  onChangeLocale: (locale: Locale) => void;
  ga4?: { website_subsection: string };
};

export const LanguageSelector = ({ menuStyle, onChangeLocale, ga4 }: Props) => {
  const { supportedLngs } = useContext(LanguageContext);
  const currentLanguage = useLanguage();
  const { t } = useTranslation('common');
  const { experimentId, variantId } = useExperiment();

  const Languages = () => (
    <HStack
      w={menuStyle ? '100%' : ''}
      justifyContent="space-evenly"
      divider={<div>|</div>}
    >
      {supportedLngs.map((locale: string) => {
        const language = getLanguagePartFromLocale(locale);

        return (
          <TagElement
            key={locale}
            ua={{
              eventLabel: locale,
              eventCategory: 'menu_cta',
              eventAction: ga4?.website_subsection,
            }}
            ga4={{
              experiment_id: experimentId,
              variant_id: variantId,
              language,
              event: 'menu_cta',
              category: 'languages',
              action: locale,
              ...ga4,
            }}
          >
            <UIButton
              background="none"
              color="ae.green"
              fontWeight={language === currentLanguage ? 'bold' : 'normal'}
              onClick={() => onChangeLocale(locale as Locale)}
              wordBreak="keep-all"
              pl={menuStyle ? 0 : ''}
              pr={menuStyle ? 0 : ''}
              _hover={{ textDecoration: 'underline', bg: '' }}
              _active={{ textDecoration: 'underline', bg: '' }}
              fontSize="15px"
              label={language.toUpperCase()}
            />
          </TagElement>
        );
      })}
    </HStack>
  );

  if (menuStyle) {
    return (
      <Flex
        flexDir="column"
        alignItems="center"
        w="100%"
        color="ae.green"
        data-testid="language-selector"
      >
        <Text display="flex" color="ae.grey_400">
          {t('common.language_menu')}
        </Text>
        <Languages />
      </Flex>
    );
  }

  return (
    <Box
      display={{ base: 'block', lg: 'flex' }}
      justifyContent={{ base: 'center', md: 'left' }}
      color="ae.green"
      data-testid="language-selector"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        pl="15px"
        pr="10px"
      >
        {t('common.language_menu')}
      </Box>

      <Languages />
    </Box>
  );
};
