import { AELink, useGetAELink, useTranslation } from '@ae/shared';
import Menu from './Menu';
import Title from './Title';

const SupportSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkTitle } = useGetAELink();

  return (
    <>
      <Title>{t('footer.support')}</Title>
      <Menu>
        {[AELink.FAQ, AELink.HelperCenterContact].map((link) => {
          //https://github.com/mui/material-ui/issues/39833
          const { key, ...linkProps } = getLinkProps(link);
          return (
            <Menu.MenuItem key={key} {...linkProps}>
              {getLinkTitle(link)}
            </Menu.MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default SupportSection;
