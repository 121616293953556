import { AxiosInstance } from 'axios';
import {
  getHostFromBrowserOrParam,
  getLocaleFromDomain,
  getProdDomains,
} from './index';

export const getBaseUrl = ({
  overridedApiDomain,
  overridedBaseApi,
  ssrHost,
}: {
  overridedApiDomain?: string;
  overridedBaseApi?: string;
  ssrHost?: string;
}) => {
  const host = getHostFromBrowserOrParam(ssrHost);

  let apiDomain;
  if (overridedApiDomain) {
    apiDomain = overridedApiDomain;
  } else if (
    process.env['NEXT_PUBLIC_API_DOMAIN'] &&
    process.env['NEXT_PUBLIC_API_DOMAIN'] !== ''
  ) {
    apiDomain = process.env['NEXT_PUBLIC_API_DOMAIN'];
  } else if (
    process.env['NX_PUBLIC_API_DOMAIN'] &&
    process.env['NX_PUBLIC_API_DOMAIN'] !== ''
  ) {
    apiDomain = process.env['NX_PUBLIC_API_DOMAIN'];
  } else {
    apiDomain = getProdDomains(host)[getLocaleFromDomain(host)];
  }

  const baseApi =
    overridedBaseApi ??
    process.env['NX_PUBLIC_BASE_API'] ??
    process.env['NEXT_PUBLIC_BASE_API'] ??
    '';

  const baseUrl = `${apiDomain}${baseApi}`;
  return { baseUrl, apiDomain, baseApi };
};

export const setBaseUrl = (axiosInstance: AxiosInstance, baseUrl: string) => {
  axiosInstance.defaults.baseURL = baseUrl;
};
