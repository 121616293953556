import { Box, Center, Heading, HStack, VStack } from '@chakra-ui/react';
import { SymbolIcon } from '@ae/icons';
import { AELink, useGetAELink, useTranslation } from '@ae/shared';
import { LinkButton, useViewport } from '@ae/shared-ui';
import { TagElement } from '@ae/tagging';

const HelpcenterSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkHref } = useGetAELink();
  const { MobileAndTablet, Desktop } = useViewport();

  const Title = () => (
    <Heading size="lg" fontSize="24px" textAlign="center">
      {t('footer.helpCenter.title')}
    </Heading>
  );

  const HelpCenterLink = () => (
    <TagElement
      ua={{
        eventAction: 'home',
        eventLabel: 'helpcenter',
      }}
      ga4={{
        event: 'client_account_cta',
        website_subsection: 'my_holidays',
        action: 'click',
        category: 'helpcenter',
      }}
    >
      <LinkButton
        label={t('footer.helpCenter.cta')}
        href={getLinkHref(AELink.HelpCenter) ?? ''}
      />
    </TagElement>
  );

  const HorizontalDivider = () => (
    <Center position="relative">
      <Box h="1px" w="100%" bgColor="ae.green" opacity={0.4} mx="20px" />
      <Center position="absolute" w="55px" bgColor="ae.grey_100">
        <SymbolIcon size="30px" color="ae.green" />
      </Center>
    </Center>
  );

  const VerticalDivider = () => (
    <Center position="relative" h="100%">
      <Box
        top="40px"
        bottom="40px"
        left={0}
        right={0}
        position="absolute"
        w="1px"
        bgColor="ae.green"
        opacity={0.4}
      />
      <Center position="absolute" h="55px" bgColor="ae.grey_100">
        <SymbolIcon size="30px" color="ae.green" />
      </Center>
    </Center>
  );

  return (
    <>
      <MobileAndTablet>
        <VStack
          py="40px"
          bgColor="ae.grey_100"
          alignItems="stretch"
          spacing="3rem"
        >
          <Center>
            <Title />
          </Center>
          <HorizontalDivider />
          <Center>
            <HelpCenterLink />
          </Center>
        </VStack>
      </MobileAndTablet>

      <Desktop>
        <Center bgColor="#F1F1F1" h="213px" px="20px">
          <HStack h="100%" spacing="65px">
            <Title />
            <VerticalDivider />
            <HelpCenterLink />
          </HStack>
        </Center>
      </Desktop>
    </>
  );
};

export default HelpcenterSection;
