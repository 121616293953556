import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from '@ae/shared';
import { Composition } from '@ae/shared-comp';
import { UINumericalPicker } from '@ae/shared-ui';

type Props = {
  value: Composition | null;
  onChange: (values: Composition) => void;
  bottomComponent: React.ReactNode | undefined;
};

export const CompositionPicker = ({
  value: defaultValue,
  onChange,
  bottomComponent,
}: Props) => {
  const { t } = useTranslation('mr');
  const [value, setValue] = useState<Composition>(
    defaultValue ? defaultValue : { persons: 0, dogs: 0 }
  );
  const { persons, dogs } = value;

  const onPersonsChange = (persons: number) => {
    setValue((prev) => {
      const newValue = { ...prev, persons };
      onChange(newValue);
      return newValue;
    });
  };

  const onDogsChange = (dogs: number) => {
    setValue((prev) => {
      const newValue = { ...prev, dogs };
      onChange(newValue);
      return newValue;
    });
  };

  return (
    <Flex flexDir="column" gap="30px" data-testid="composition-picker">
      <Flex flexDir="column" gap="20px">
        <Flex justifyContent="space-between" alignItems="flex-start" gap="20px">
          <Flex flexDir="column">
            <Text fontSize="14px">{t('mr1.composition.person_label')}</Text>
            <Text fontSize="14px" color="ae.grey_400">
              {t('mr1.composition.person_label2')}
            </Text>
          </Flex>
          <UINumericalPicker
            addButtonAriaLabel={t('mr1.add_person_aria')}
            removeButtonAriaLabel={t('mr1.remove_person_aria')}
            inputAriaLabel={t('mr1.persons_count_aria')}
            min={0}
            max={60}
            value={persons}
            onChange={onPersonsChange}
          />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" gap="20px">
          <Text fontSize="14px">{t('mr1.composition.dog_label')}</Text>
          <UINumericalPicker
            addButtonAriaLabel={t('mr1.add_dog_aria')}
            removeButtonAriaLabel={t('mr1.remove_dog_aria')}
            inputAriaLabel={t('mr1.dogs_count_aria')}
            min={0}
            max={4}
            value={dogs}
            onChange={onDogsChange}
          />
        </Flex>
      </Flex>

      {bottomComponent}
    </Flex>
  );
};
