import { useContext, useEffect } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import {
  RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental,
  useApiFavoritesListGetCollection,
} from '@ae/data-access';
import { ChevronRightIcon, PlusIcon } from '@ae/icons';
import { useMe, useTranslation } from '@ae/shared';
import { UIButton } from '@ae/shared-ui';
import RentalFavoriteListItem, {
  SkeletonRentalFavoriteListItem,
} from '../RentalFavoriteListItem/RentalFavoriteListItem';
import { ListRentalFavoriteListsContext } from './ListRentalFavoriteListsContext';

const LIST_MARKUP_ID = 'list';

const isRentalInFavoriteList = (
  rentalFavoriteList: RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental,
  rentalId: string
) =>
  rentalFavoriteList?.favorites?.find((f) => String(f) === rentalId) !==
  undefined;

const CreateFavoritesListButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('favorites');

  return (
    <>
      <Box h="1px" w="100%" bgColor="ae.greyF1" />
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        alignContent="stretch"
        px="10px"
        py="20px"
        cursor="pointer"
        _hover={{ textDecoration: 'underline' }}
        onClick={onClick}
      >
        <Flex flexDir="row" alignItems="center">
          <PlusIcon size="11px" />
          <Text color="ae.green" ml="15px">
            {t('favorites.new_list')}
          </Text>
        </Flex>
        <ChevronRightIcon size="11px" />
      </Flex>
      <Box h="1px" w="100%" bgColor="ae.greyF1" />
    </>
  );
};

type Props = {
  rentalId: string;
  onCreateFavoriteListButtonClick: () => void;
  onConfirmationClick: () => void;
  onFavoriteChange?: () => void;
  waitingClosing: boolean;
};

const Divider = <Box h="1px" w="100%" bgColor="#e2e8f0" />;

export const ListRentalFavoriteListsTab = ({
  rentalId,
  onCreateFavoriteListButtonClick,
  onConfirmationClick,
  onFavoriteChange,
  waitingClosing,
}: Props) => {
  const { t } = useTranslation('favorites');
  const { authenticated } = useMe();
  const { setOngoingMutations } = useContext(ListRentalFavoriteListsContext);
  const {
    data: rentalFavoriteLists,
    isLoading: isFavoriteListsLoading,
    isFetching: isFavoriteListsFetching,
    refetch: refetchFavoriteLists,
  } = useApiFavoritesListGetCollection(
    { pagination: false },
    {
      query: {
        enabled: authenticated,
      },
    }
  );

  useEffect(() => {
    if (!isFavoriteListsFetching) {
      setOngoingMutations(LIST_MARKUP_ID, false);
    }
  }, [isFavoriteListsFetching]);

  const onIsCheckedChange = () => {
    setOngoingMutations(LIST_MARKUP_ID, true);
    refetchFavoriteLists({ cancelRefetch: true });
    onFavoriteChange && onFavoriteChange();
  };

  return (
    <Box data-testid="add-rental-to-favorites-list-tab">
      <VStack align="left" divider={Divider}>
        {isFavoriteListsLoading &&
          Array.from({ length: 3 }).map((_, i) => (
            <SkeletonRentalFavoriteListItem key={i} />
          ))}

        {!isFavoriteListsLoading &&
          rentalFavoriteLists &&
          rentalFavoriteLists['hydra:member'].map(
            (
              list: RentalFavoriteListJsonldFavoriteListGetFavoriteUserGetFavoriteGetReadRental
            ) => (
              <RentalFavoriteListItem
                key={list.id}
                favoriteList={list}
                rentalId={rentalId}
                isChecked={isRentalInFavoriteList(list, rentalId)}
                onIsCheckedChange={onIsCheckedChange}
              />
            )
          )}
      </VStack>

      <Box py="10px">
        {Divider}
        <CreateFavoritesListButton onClick={onCreateFavoriteListButtonClick} />
        {Divider}
      </Box>

      <UIButton
        mt="16px"
        w="100%"
        borderRadius="50px"
        label={t('favorites.list.append_rental_close_cta')}
        isLoading={waitingClosing}
        onClick={onConfirmationClick}
      />
    </Box>
  );
};
