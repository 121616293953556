import { isSameMonth, isSameYear } from 'date-fns';
import { LocalityJsonldReadLocality } from '@ae/data-access';
import { Locale } from '@ae/i18n';
import {
  capitalizeFirstLetter,
  formatToBrusselsTz,
  useTranslation,
} from '@ae/shared';
import {
  Composition,
  isFullMonth,
  StayDate,
  stayTypeLabels,
  useLocalityInCookie,
} from '@ae/shared-comp';

export const defaultCompositionLabel = 'mr1.composition.selection_cta';
export const defaultStayDateLabel = 'mr1.date.selection_cta';
export const defaultLocalityLabel = 'mr1.locality.selection_cta';

export const useSearchLabel = ({
  localities,
}: {
  localities: LocalityJsonldReadLocality[] | null | undefined;
}) => {
  const { t } = useTranslation('mr');
  const { locality: localityFromCookie } = useLocalityInCookie();

  const getCompositionLabel = (
    composition: Composition | null,
    defaultValue: string = defaultCompositionLabel
  ) => {
    if (!composition || (!composition.persons && !composition.dogs)) {
      return t(defaultValue);
    }

    let compositionLabel = '';
    const { persons, dogs } = composition;

    if (persons) {
      compositionLabel =
        persons === 1
          ? t('mr1.composition.person')
          : t('mr1.composition.person_other', { count: persons });
    }

    if (dogs) {
      const dogLabel =
        dogs > 0 ? t('mr1.composition.dog', { count: dogs }) : '';

      if (compositionLabel.length) {
        compositionLabel = `${compositionLabel}, ${dogLabel}`;
      } else {
        compositionLabel = `${dogLabel}`;
      }
    }

    return capitalizeFirstLetter(compositionLabel);
  };

  const getStayDateLabel = (
    stayDate: StayDate | null | undefined,
    defaultValue: string = defaultStayDateLabel,
    locale: Locale
  ) => {
    if (stayDate?.startDate && stayDate?.endDate) {
      if (
        isFullMonth(stayDate.startDate, stayDate.endDate) &&
        stayDate?.stayType
      ) {
        const monthAndYear = formatToBrusselsTz(
          stayDate?.startDate,
          'MMMM yyyy',
          locale
        );

        return `${t(stayTypeLabels[stayDate.stayType])}, ${monthAndYear}`;
      } else {
        if (isSameMonth(stayDate.startDate, stayDate.endDate)) {
          return `${t('mr1.stay_aria', {
            start: formatToBrusselsTz(stayDate.startDate, 'dd', locale),
            end: formatToBrusselsTz(stayDate.endDate, 'dd MMMM yyyy', locale),
          })}`;
        }
        if (isSameYear(stayDate.startDate, stayDate.endDate)) {
          return `${t('mr1.stay_aria', {
            start: formatToBrusselsTz(stayDate.startDate, 'dd MMMM', locale),
            end: formatToBrusselsTz(stayDate.endDate, 'dd MMMM yyyy', locale),
          })}`;
        }

        return `${t('mr1.stay_aria', {
          start: formatToBrusselsTz(stayDate.startDate, 'dd MMMM yyyy', locale),
          end: formatToBrusselsTz(stayDate.endDate, 'dd MMMM yyyy', locale),
        })}`;
      }
    } else if (stayDate?.stayType) {
      return t(stayTypeLabels[stayDate.stayType]);
    } else {
      return t(defaultValue);
    }
  };

  const getLocalityLabel = (
    locality: number | null | undefined,
    defaultValue: string = defaultLocalityLabel
  ) => {
    if (!localities?.length) {
      return localityFromCookie?.name ?? t(defaultValue);
    }
    if (locality) {
      return (
        localities?.find((l) => l.id === locality)?.name ?? t(defaultValue)
      );
    }
    return t(defaultValue);
  };

  return {
    getCompositionLabel,
    getStayDateLabel,
    getLocalityLabel: getLocalityLabel,
  };
};
