'use client';

import { useEffect, useState } from 'react';
import { Tag } from '@chakra-ui/react';

export const HeaderStagingTag = () => {
  const [isStaging, setIsStaging] = useState(false);
  const [isLocal, setIsLocal] = useState(false);

  useEffect(() => {
    if (window.location.hostname.includes('staging.ardennes-etape.com')) {
      setIsStaging(true);
    }
    if (
      window.location.hostname.includes('localhost') ||
      window.location.hostname.includes('loc.ardennes-etape.com')
    ) {
      setIsLocal(true);
    }
  }, []);

  if (!isStaging && !isLocal) return null;
  return (
    <Tag
      id="stagingTag"
      position="absolute"
      pointerEvents="none"
      userSelect="none"
      top={1}
      left="50%"
      transform="translateX(-50%)"
      zIndex={10000}
      size="sm"
      fontWeight="bold"
      colorScheme="red"
    >
      {isLocal ? 'Local' : 'Staging'} Environment
    </Tag>
  );
};
