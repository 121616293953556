import { Box, HStack } from '@chakra-ui/react';
import { SocialLink, useSocialLinks, useTranslation } from '@ae/shared';
import { UILinkIcon } from '@ae/shared-ui';
import Title from './Title';

const FollowUsSection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkIcon } = useSocialLinks();

  return (
    <Box id="followUs">
      <Title>{t('footer.follow_us')}</Title>
      <HStack spacing="15px">
        {[
          SocialLink.Facebook,
          SocialLink.LinkedIn,
          SocialLink.Instagram,
          SocialLink.Youtube,
          SocialLink.Pinterest,
        ].map((link) => {
          //https://github.com/mui/material-ui/issues/39833
          const { key, ...linkProps } = getLinkProps(link);
          return (
            <UILinkIcon
              key={key}
              variant="primary"
              w="40px"
              h="40px"
              {...linkProps}
              icon={getLinkIcon(link)}
            />
          );
        })}
      </HStack>
    </Box>
  );
};

export default FollowUsSection;
