import React, { forwardRef } from 'react';
import { Box, Center, Flex, FlexboxProps, Text } from '@chakra-ui/react';
import { ChevronRightIcon, XIcon } from '@ae/icons';
import { TagElement } from '@ae/tagging';

type Props = {
  label: string;
  dataTestId?: string;
  ariaLabel?: string;
  onClick?: () => void;
  showReset?: boolean;
  onReset?: () => void;
  eventLabelSuffix: string;
};

const SearchField = forwardRef<HTMLDivElement, Props & FlexboxProps>(
  (
    {
      label,
      dataTestId,
      ariaLabel,
      onClick,
      showReset,
      onReset,
      eventLabelSuffix,
      ...props
    },
    ref
  ) => {
    return (
      <TagElement
        ua={{
          eventCategory: 'Header',
          eventAction: 'Rechercher',
          eventLabel: `MR1_Interraction - ${eventLabelSuffix}`,
        }}
      >
        <Flex
          ref={ref}
          data-testid={dataTestId}
          aria-label={ariaLabel}
          gap="10px"
          borderWidth="1px"
          borderColor="ae.grey_200"
          borderRadius="20px"
          h="54px"
          w="100%"
          px="20px"
          color="ae.green"
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          role="button"
          onClick={onClick}
          {...props}
        >
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {label}
          </Text>
          <Center
            flexShrink={0}
            bgColor="ae.grey_200"
            h="22px"
            w="22px"
            borderRadius="22px"
          >
            {showReset ? (
              <Box
                role="button"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onReset && onReset();
                }}
              >
                <Box display="flex" justifyContent="center" m="7px">
                  <XIcon size="10px" />
                </Box>
              </Box>
            ) : (
              <ChevronRightIcon size="10px" />
            )}
          </Center>
        </Flex>
      </TagElement>
    ) as React.ReactNode;
  }
);

export default SearchField;
