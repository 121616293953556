'use client';

import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  Circle,
  HStack,
  IconButton,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { DotLoadingIcon, HamburgerIcon, UserIcon } from '@ae/icons';
import { useTranslation, useUserInitials } from '@ae/shared';
import { UINotificationBullet } from '@ae/shared-ui';

type Props = {
  hasNotificationBullet: boolean;
  isMenuButton?: boolean;
};

const HamburgerButton = ({
  hasNotificationBullet,
  isMenuButton = false,
  ...other
}: Props & ButtonProps) => {
  const { t } = useTranslation('header');
  const { userInitials, userIsLoading } = useUserInitials();
  const [showLoadingIndicator, setShowLoadingIndicator] =
    useState<boolean>(false);
  const refTimer = useRef<number | null>(null);

  useEffect(() => {
    // if (process.env['STORYBOOK_TEST_VT']) {
    //   return; // Animations alter visual regression tests.
    // }

    setShowLoadingIndicator(false);
    if (userIsLoading) {
      refTimer.current = window.setTimeout(() => {
        setShowLoadingIndicator(true);
      }, 2000);
    }
    return () => {
      if (refTimer.current) window.clearTimeout(refTimer.current);
    };
  }, [userIsLoading, setShowLoadingIndicator]);

  const Wrapper = isMenuButton ? MenuButton : Button;
  const avatarIcon = userInitials ? (
    <Circle size="28px" bgColor="ae.pink" color="ae.green" pt="1px">
      <Text fontSize="12px">{userInitials}</Text>
    </Circle>
  ) : (
    <Circle size="28px" bgColor="ae.grey97" color="ae.grey_300">
      <UserIcon color="ae.grey_300" />
    </Circle>
  );

  return (
    <Wrapper
      {...other}
      as={IconButton}
      aria-label={t('header.menu.aria')}
      icon={
        <UINotificationBullet bullet={hasNotificationBullet} adjustXY={1}>
          <HStack>
            <HamburgerIcon size="20px" color="white" />
            {showLoadingIndicator ? (
              <Circle size="28px" bgColor="ae.grey97">
                <DotLoadingIcon />
              </Circle>
            ) : (
              avatarIcon
            )}
          </HStack>
        </UINotificationBullet>
      }
      data-testid="header-open-menu-button"
      variant="iconOutlined"
      p="0px 8px 0px 11px"
      h="40px"
    />
  );
};

export default HamburgerButton;
