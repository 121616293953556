import { Box, Center, Text } from '@chakra-ui/react';
import { format, isSameMonth } from 'date-fns';
import { Locale, useLanguage } from '@ae/i18n';
import { formatToBrusselsTz } from '@ae/shared';
import { StayDate } from '@ae/shared-comp';
import { CalendarDayStayDatesPopover } from './CalendarDayStayDatesPopover';
import { useStayDates } from './hooks';

type Props = {
  day: Date;
  selectedMonth: Date;
  onClick: (interval: StayDate | null) => void;
  focusCalendarPopover: () => void;
};

export const CalendarDay = ({
  day,
  selectedMonth,
  onClick,
  focusCalendarPopover,
}: Props) => {
  const language = useLanguage();

  const {
    stayDateOnToday,
    stayDateOnDayBefore,
    stayDateOnDayAfter,
    stayDatesOnTodayWithSameEndDate,
    isDayOnSelectedStayDate,
    stayDatesStartingOnDay,
    nearestStayDateFromToday,
    isFirstDayOfStayDate,
    isDayBeforeOnSelectedStayDate,
    isDayAfterOnySelectedStayDate,
    isMonthSelected,
  } = useStayDates(day);

  const getLeftColor = () => {
    if (
      !isMonthSelected &&
      isDayOnSelectedStayDate &&
      isDayBeforeOnSelectedStayDate
    ) {
      return 'ae.green';
    } else if (stayDateOnDayBefore && stayDateOnToday) {
      return 'ae.pink';
    } else {
      return '';
    }
  };

  const getRightColor = () => {
    if (
      !isMonthSelected &&
      isDayOnSelectedStayDate &&
      isDayAfterOnySelectedStayDate
    ) {
      return 'ae.green';
    } else if (stayDateOnDayAfter && stayDateOnToday) {
      return 'ae.pink';
    } else {
      return '';
    }
  };

  const getCenterColor = () => {
    if (!isMonthSelected && isDayOnSelectedStayDate) {
      return 'ae.green';
    } else if (stayDateOnToday) {
      return 'ae.pink';
    } else {
      return '';
    }
  };

  const getBubbleColor = () => (isFirstDayOfStayDate ? 'white' : '');

  const getTextColor = () => {
    if (isMonthSelected) {
      return stayDateOnToday ? 'ae.green' : 'ae.grey_300';
    } else if (isDayOnSelectedStayDate) {
      return isFirstDayOfStayDate ? 'ae.green' : 'white';
    } else if (stayDateOnToday) {
      return isDayOnSelectedStayDate ? 'white' : 'ae.green';
    } else {
      return 'ae.grey_300';
    }
  };

  const getTextFontWeight = () => (isFirstDayOfStayDate ? 'bold' : 'semibold');

  const isSelectable =
    isFirstDayOfStayDate && stayDatesStartingOnDay.length === 1;

  const formattedDate = formatToBrusselsTz(
    day,
    'dd-MM-yyyy',
    language as Locale
  );

  // Do not render days that are not in the selected month
  if (!isSameMonth(day, selectedMonth)) {
    return <Box />;
  }

  return (
    <Center
      data-testid={`calendar-day-${formattedDate}`}
      position="relative"
      role="button"
      cursor={isSelectable ? 'pointer' : 'initial'}
      onClick={() => isSelectable && onClick(nearestStayDateFromToday)}
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      <Box
        position="absolute"
        left={0}
        top={0}
        bottom={0}
        w="50%"
        cursor="pointer"
        bgColor={getLeftColor()}
      />

      <Box
        position="absolute"
        right={0}
        top={0}
        bottom={0}
        w="50%"
        cursor="pointer"
        bgColor={getRightColor()}
      />

      <CalendarDayStayDatesPopover
        formattedDate={formattedDate}
        day={day}
        onClick={onClick}
        focusCalendarPopover={focusCalendarPopover}
        stayDatesStartingOnDay={stayDatesStartingOnDay}
        stayDatesOnTodayWithSameEndDate={stayDatesOnTodayWithSameEndDate}
      >
        <Center
          position="relative"
          h="38px"
          w="38px"
          borderRadius="38px"
          bgColor={getCenterColor()}
        >
          <Center
            h="30px"
            w="30px"
            borderRadius="24px"
            bgColor={getBubbleColor()}
          >
            <Text
              fontSize="14px"
              fontWeight={getTextFontWeight()}
              color={getTextColor()}
            >
              {format(day, 'd')}
            </Text>
          </Center>
        </Center>
      </CalendarDayStayDatesPopover>
    </Center>
  );
};
