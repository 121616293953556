'use client';

import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
// import { useCookies } from 'react-cookie';
// import { useGetDomain } from '../hooks';
import TagManager from 'react-gtm-module';
import {
  experimentConfigurations,
  ExperimentGroup,
  ExperimentSelector,
} from './config';
import { getGroup } from './utils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google_optimize: any;
  }
}

export type ExperimentGroups = Partial<{
  [x in ExperimentGroup]: { percent: number };
}>;

export type ExperimentConfiguration = {
  experimentSelector: ExperimentSelector;
  experimentId: string;
  experimentGroups?: ExperimentGroups;
};

export type ExperimentConfigurations = {
  [x in ExperimentSelector]: ExperimentConfiguration;
};

export type GroupSelections = { [x in ExperimentSelector]: ExperimentGroup };

interface ExperimentContextType {
  groupSelections: GroupSelections;
  experimentConfigurations: ExperimentConfigurations;
}

export const ExperimentContext = createContext<ExperimentContextType>(
  {} as ExperimentContextType
);

export const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  // const [cookies, setCookies] = useCookies();
  // const { racineDomain } = useGetDomain();
  const [groupSelections, setGroupSelections] = useState<GroupSelections>({
    [ExperimentSelector.LanguageHeaderMenu]: ExperimentGroup.Control,
  });
  const googleOptimizeInitTriesCount = useRef(0);

  useEffect(() => {
    return; // ab testing disabled for now as the visual behavior is not great.

    if (process.env['NX_PUBLIC_DISABLE_AB_TESTING']) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'optimize.activate',
      },
    });

    const intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const selectors: Partial<GroupSelections> = {};

        for (const [, experimentConfiguration] of Object.entries(
          experimentConfigurations
        )) {
          const variantId = window.google_optimize.get(
            experimentConfiguration.experimentId
          );
          selectors[experimentConfiguration.experimentSelector] =
            getGroup(variantId);
        }

        setGroupSelections({ ...(selectors as GroupSelections) });
        clearInterval(intervalId);
      } else {
        if (++googleOptimizeInitTriesCount.current === 100) {
          clearInterval(intervalId);
        }
      }
    }, 20);
  }, []);

  // useEffect(() => {
  //   const selectors: Partial<GroupSelections> = {};

  //   for (const [, experimentConfiguration] of Object.entries(
  //     experimentConfigurations
  //   )) {
  //     let variantId = '0';

  //     if (!process.env['NX_PUBLIC_DISABLE_AB_TESTING']) {
  //       // const variantCookieName = `experiment_${experimentConfiguration.experimentId}`;
  //       // variantId = cookies[variantCookieName];

  //       // if (variantId === undefined) {
  //       //   variantId = getVariant(
  //       //     getPercents(experimentConfiguration.experimentGroups)
  //       //   );

  //       //   setCookies(variantCookieName, variantId, {
  //       //     domain: racineDomain,
  //       //     path: '/',
  //       //     maxAge: ms('7d'),
  //       //   });
  //       // }
  //     }

  //     selectors[experimentConfiguration.experimentSelector] =
  //       getGroup(variantId);
  //   }

  //   setGroupSelections({ ...(selectors as GroupSelections) });
  // }, [cookies, racineDomain, setCookies]);

  return (
    <ExperimentContext.Provider
      value={{ groupSelections, experimentConfigurations }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};
