import { useEffect } from 'react';

export const useVersionLogging = (appName: string) => {
  useEffect(() => {
    const style = 'color: #003f35; background:#eee;  font-size:12px;';
    console.log(
      `%c🚀 Version ${appName}: ${process.env['NX_PUBLIC_BUILD_VERSION'] ?? process.env['NEXT_PUBLIC_BUILD_VERSION']}🚀`,
      style
    );
  }, [appName]);
};
