import { createRoot } from 'react-dom/client';
import { StandaloneComponent } from '../StandaloneComponent';
import { RentalFavoritesAuthentication } from './RentalFavoritesAuthentication';

const RentalFavoritesStandaloneComponent = () => {
  return (
    <StandaloneComponent appName="favorites">
      <RentalFavoritesAuthentication />
    </StandaloneComponent>
  );
};

const container = document.getElementById('favorites');
if (container) {
  const root = createRoot(container);
  root.render(<RentalFavoritesStandaloneComponent />);
}
