import { AELink, useGetAELink, useTranslation } from '@ae/shared';
import { TagElement } from '@ae/tagging';
import Menu from './Menu';
import Title from './Title';

const AESection = () => {
  const { t } = useTranslation('footer');
  const { getLinkProps, getLinkTitle } = useGetAELink();

  return (
    <>
      <Title>{t('footer.title')}</Title>
      <Menu>
        {[AELink.Jobs, AELink.About, AELink.GiftCard].map((link) => {
          //https://github.com/mui/material-ui/issues/39833
          const { key, ...linkProps } = getLinkProps(link);
          return (
            <TagElement
              key={link}
              ga4={{
                event: 'client_account_cta',
                action: 'main_cta',
                category: getLinkProps(link)['trackingname'],
              }}
            >
              <Menu.MenuItem key={key} {...linkProps}>
                {getLinkTitle(link)}
              </Menu.MenuItem>
            </TagElement>
          );
        })}
      </Menu>
    </>
  );
};

export default AESection;
