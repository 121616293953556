import {
  BlogIcon,
  CalendarIcon,
  DiscountIcon,
  GiftCardIcon,
  HeartIcon,
  HelpCenterIcon,
  HomeIcon,
  NewLeadIcon,
  SearchIcon,
  UserIcon,
} from '@ae/icons';
import {
  AELink,
  useCurrentDomain,
  useGetAELink,
  usePathname,
  useTranslation,
} from '@ae/shared';

type Props = {
  hasProfileNotification?: boolean;
  hasBookingNotification?: boolean;
  hideBecomeOwner?: boolean;
  hideReference?: boolean;
  hideHelpCenter?: boolean;
  onSearchRentalReferenceModalOpen?: () => void;
};

export type MenuItemT = {
  href: string | undefined;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  gtmLabel: string;
  auth: boolean;
  bullet: boolean;
  hide: boolean | undefined;
  iconSize?: string | undefined;
  seperator?: boolean;
};

export const useMenu = ({
  hasProfileNotification = false,
  hasBookingNotification = false,
  hideBecomeOwner = false,
  hideReference = false,
  hideHelpCenter = false,
  onSearchRentalReferenceModalOpen,
}: Props) => {
  const { t } = useTranslation('header');
  const { getLinkHref } = useGetAELink();
  const { currentDomain } = useCurrentDomain();
  const pathname = usePathname();

  const basePath = pathname.includes('/guest') ? '' : `${currentDomain}/guest`;

  return [
    {
      href: `${basePath}/`,
      icon: <HomeIcon />,
      label: t('header.menu.home'),
      gtmLabel: 'start',
      auth: true,
      bullet: false,
      hide: false,
    },
    {
      href: `${basePath}/bookings?type=soon`,
      icon: <CalendarIcon />,
      label: t('header.menu.stay'),
      gtmLabel: 'my_holidays',
      auth: true,
      bullet: hasBookingNotification,
      hide: false,
    },
    {
      href: `${basePath}/favorites`,
      icon: <HeartIcon />,
      label: t('header.menu.favorite'),
      gtmLabel: 'my_favorites',
      auth: true,
      bullet: false,
      hide: false,
    },
    {
      href: `${basePath}/vouchers/privilege`,
      icon: <DiscountIcon />,
      label: t('header.menu.reduction'),
      gtmLabel: 'discounts',
      auth: true,
      bullet: false,
      hide: false,
    },
    {
      href: `${basePath}/profile`,
      icon: <UserIcon />,
      label: t('header.menu.profile'),
      gtmLabel: 'my_profile',
      auth: true,
      bullet: hasProfileNotification,
      hide: false,
      seperator: true,
    },

    {
      href: getLinkHref(AELink.Blog),
      icon: <BlogIcon />,
      label: t('header.menu.blog'),
      gtmLabel: 'blog',
      auth: false,
      bullet: false,
      hide: false,
      iconSize: '30px',
    },

    {
      onClick: onSearchRentalReferenceModalOpen,
      icon: <SearchIcon />,
      label: t('header.menu.reference'),
      gtmLabel: 'reference',
      auth: false,
      bullet: false,
      hide: hideReference,
      iconSize: '30px',
    },
    {
      href: getLinkHref(AELink.GiftCard),
      icon: <GiftCardIcon />,
      label: t('header.menu.giftCard'),
      gtmLabel: 'giftCard',
      auth: false,
      bullet: false,
      hide: false,
    },
    {
      href: getLinkHref(AELink.HelpCenter),
      icon: <HelpCenterIcon />,
      label: t('header.help_center'),
      gtmLabel: 'reference',
      auth: false,
      bullet: false,
      hide: hideHelpCenter,
      iconSize: '30px',
    },
    {
      href: getLinkHref(AELink.BecomeOwner),
      icon: <NewLeadIcon />,
      label: t('header.ctaLead'),
      gtmLabel: 'become_owner',
      auth: false,
      bullet: false,
      hide: hideBecomeOwner,
    },
  ];
};
