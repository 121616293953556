import { useCallback, useContext, useMemo } from 'react';
import {
  beProdDomains,
  getHostFromBrowserOrParam,
  getProdDomains,
  LanguageContext,
  Locale,
  useApiDomain,
} from '@ae/i18n';
import { RouterContext } from '../providers';

// --------------------- helpers ------------------------
// example:
// getRacineDomain('https://www.ardennes-etape.be') => '.ardennes-etape.be'
// getRacineDomain('https://www.ardennes-etape.com') => '.ardennes-etape.com'
// getRacineDomain('https://fr.ardennes-etape.be') => '.ardennes-etape.be'
const getRacineDomain = (domain: string) => {
  try {
    const url = new URL(domain);
    url.port = '';
    return `.${url.hostname.substring(url.hostname.indexOf('ardennes-etape'))}`;
  } catch {
    return '';
  }
};

// --------------------- hook ------------------------
export const useGetDomain = () => {
  const { locale } = useContext(LanguageContext);
  const router = useContext(RouterContext);
  const apiDomain = useApiDomain();

  const prodDomains = useMemo(
    () => getProdDomains(getHostFromBrowserOrParam(router.host)),
    []
  );
  const prodDomain = useMemo(() => prodDomains[locale as Locale], [locale]);
  const getProdDomain = useCallback(
    (locale: Locale) => prodDomains[locale],
    []
  );

  const isBeProdDomain = useMemo(() => {
    return prodDomain?.includes('.be');
  }, [prodDomain]);

  const beProdDomain = useMemo(() => beProdDomains[locale as Locale], [locale]);
  const getBeProdDomain = useCallback(
    (locale: Locale) => beProdDomains[locale],
    []
  );

  const racineDomain = useMemo(() => {
    try {
      const url = new URL(getHostFromBrowserOrParam());
      return getRacineDomain(url.href);
    } catch {
      return '';
    }
  }, []);

  return {
    authDomain: apiDomain,
    apiDomain: apiDomain,
    prodDomain,
    getProdDomain,
    beProdDomain,
    getBeProdDomain,
    racineDomain,
    getRacineDomain,
    isBeProdDomain,
  };
};
