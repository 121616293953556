import { Box, BoxProps, Circle } from '@chakra-ui/react';
import { useTranslation } from '@ae/shared';

const PADDING = 4;

type Props = {
  bullet: boolean;
  children: React.ReactNode;
  adjustXY?: number;
};

export const UINotificationBullet = ({
  children,
  bullet,
  adjustXY = 0,
  ...props
}: Props & BoxProps) => {
  const { t } = useTranslation();

  return (
    <Box position="relative" display="inline-block" color="ae.green">
      {children}
      {bullet && (
        <Box
          position="absolute"
          top={`-${PADDING + adjustXY}px`}
          right={`-${PADDING + adjustXY}px`}
          borderRadius="10px"
          p={`${PADDING}px`}
          {...props}
        >
          <Circle
            aria-label={t('image.alt.notification_bullet')}
            size="10px"
            bg="ae.red"
          />
        </Box>
      )}
    </Box>
  );
};
UINotificationBullet.displayName = 'UINotificationBullet';
