import { BackIcon } from '@ae/icons';
import { useRouter } from '@ae/shared';
import { UIRoundIcon } from '@ae/shared-ui';
import { useTrackEvent } from '@ae/tagging';

type Props = {
  variant?: 'green' | 'white' | 'black';
};

export const UIBackLink = ({ variant = 'white' }: Props) => {
  const { goBack } = useRouter();
  const trackEvent = useTrackEvent();

  let iconFill = 'white';
  if (variant === 'green') iconFill = 'ae.green';
  else if (variant === 'black') iconFill = 'black';

  const onClick = () => {
    trackEvent();
    goBack();
  };

  return (
    <UIRoundIcon
      aria-label="back"
      color={iconFill}
      icon={<BackIcon color={variant} />}
      size="sm"
      onClick={onClick}
      sx={{
        _hover: {
          bg: 'ae.pink',
          borderColor: 'ae.pink',
        },
        _active: {
          bg: 'ae.pink',
          borderColor: 'ae.pink',
        },
      }}
    />
  );
};
