'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export type ListRentalFavoriteListsContextType = {
  setOngoingMutations: (id: string, value: boolean) => void;
};

export const ListRentalFavoriteListsContext =
  createContext<ListRentalFavoriteListsContextType>({
    setOngoingMutations: (_id: string, _value: boolean) => {},
  });

export const ListRentalFavoriteListsContextProvider = ({
  children,
  setOngoingMutations,
}: {
  children: React.ReactNode;
  setOngoingMutations: (id: string, value: boolean) => void;
}) => {
  return (
    <ListRentalFavoriteListsContext.Provider value={{ setOngoingMutations }}>
      {children}
    </ListRentalFavoriteListsContext.Provider>
  );
};
