'use client';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react';
import {
  Trans as ReactTrans,
  useTranslation as useReactTranslation,
  UseTranslationResponse as UseReactTranslationResponse,
} from 'react-i18next';
import { i18nNamespaces } from '@ae/i18n';

type UseTranslationType = (
  ns: string | string[],
  options?: any
) => UseTranslationResponse;

type TransType = typeof ReactTrans;

type UseTranslationResponse = UseReactTranslationResponse<any, any>;

interface I18nContextState {
  useTranslation: UseTranslationType;
  Trans: TransType;
}

const I18nContext = createContext<I18nContextState>({
  useTranslation: useReactTranslation as UseTranslationType,
  Trans: ReactTrans,
});

export const useTranslation = (
  ns: string | string[] = i18nNamespaces[0], // if no namespace given, use default
  options?: any
): UseTranslationResponse => {
  const { useTranslation } = useContext(I18nContext);
  return useTranslation(ns, options);
};

export const useTrans = (): TransType => {
  const { Trans } = useContext(I18nContext);
  return Trans;
};

interface I18nProviderProps {
  useTranslation: UseTranslationType;
  Trans: TransType;
  children: React.ReactNode;
}

export const I18nProvider = ({
  useTranslation,
  Trans,
  children,
}: I18nProviderProps) => {
  return (
    <I18nContext.Provider value={{ useTranslation, Trans }}>
      {children}
    </I18nContext.Provider>
  );
};
