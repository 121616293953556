/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkWrapper, UIButton, UIButtonProps } from '@ae/shared-ui';

type Props = {
  href: string;
  target?: string;
  datatestid?: string;
};

type Ref = HTMLButtonElement;

export const LinkButton = React.forwardRef<Ref, Props & UIButtonProps>(
  ({ href, target, datatestid, ...rest }, ref) => (
    <LinkWrapper target={target} href={href}>
      <a data-testid={datatestid}>
        <UIButton ref={ref} {...rest} />
      </a>
    </LinkWrapper>
  )
);
