import React from 'react';
import { IconButtonProps, LinkWrapper, UIRoundIcon } from '@ae/shared-ui';

type Props = {
  href: string;
  target?: string | undefined;
};

type Ref = HTMLButtonElement;

export const UILinkIcon = React.forwardRef<Ref, Props & IconButtonProps>(
  ({ href, target, ...rest }, ref) => (
    <LinkWrapper href={href} target={target}>
      <UIRoundIcon ref={ref} as="a" {...rest} />
    </LinkWrapper>
  )
);
