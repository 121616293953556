import { Flex, HStack } from '@chakra-ui/react';
import { useTranslation } from '@ae/shared';
import { UIButton, useViewport } from '@ae/shared-ui';

export const ConfirmButtons = ({
  onReset,
  onConfirm,
}: {
  onReset: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation('mr');
  const { isMobileOrTablet } = useViewport();

  return (
    <Flex justifyContent={isMobileOrTablet ? 'space-between' : 'flex-end'}>
      {isMobileOrTablet && (
        <UIButton variant="linkUnderlined" size="sm" onClick={onReset}>
          {t('mr1.back')}
        </UIButton>
      )}
      <HStack justifyContent="flex-end" gap="10px">
        <UIButton
          whiteSpace="nowrap"
          variant="tertiary"
          size="sm"
          onClick={onReset}
        >
          {t('mr1.erase_cta')}
        </UIButton>
        <UIButton
          whiteSpace="nowrap"
          variant="secondary"
          size="sm"
          onClick={onConfirm}
        >
          {t('mr1.confirm_cta')}
        </UIButton>
      </HStack>
    </Flex>
  );
};
