import { format } from 'date-fns';
import {
  StayDateAvailableJsonldReadStayDateAvailableStayType,
  useApiLocalitiesGetCollection,
} from '@ae/data-access';
import { useTranslation } from '@ae/shared';
import { SearchValues } from '@ae/shared-comp';
import { defaultLocalityLabel } from '../ui/Mr1/useSearchLabels';

// Constants to represent "no value" flags for data processing
export const NO_PERSONS_VALUE_FLAG = 999;
export const NO_DOGS_COUNT_VALUE_FLAG = 999;
export const NO_DATE_VALUE_FLAG = '01-01-1900';
export const NO_BOOKING_DATE_VALUE_FLAG = '01-01-1900';

interface AnalyticsDataLayer {
  booking_number_people?: number;
  booking_capacity?: string;
  booking_dog?: number;
  booking_outbound_date?: string;
  booking_inbound_date?: string;
  booking_period?: string;
  booking_booking_date?: string;
  booking_length_of_stay?: number;
  booking_holiday_break?: string;
  booking_location?: string;
  booking_horizon?: number;
  [key: string]: any; // For any additional properties
}

const CAPACITY_RANGES: [number, number, string][] = [
  [0, 1, '1-2'],
  [2, 2, '2-3'],
  [4, 5, '4-5'],
  [6, 7, '6-7'],
  [8, 8, '8-8'],
  [9, 9, '9-9'],
  [10, 12, '10-12'],
  [13, 15, '13-15'],
  [16, 20, '16-20'],
  [21, 24, '21-24'],
  [25, 29, '25-29'],
  [30, 34, '30-34'],
  [35, Infinity, '35+'],
];

// Map stay types to readable values
const STAY_TYPE_MAPPING: Record<
  StayDateAvailableJsonldReadStayDateAvailableStayType,
  string
> = {
  [StayDateAvailableJsonldReadStayDateAvailableStayType.wk]: 'weekend',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.lw]: 'long_weekend',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.mw]: 'midweek',
  [StayDateAvailableJsonldReadStayDateAvailableStayType['1w']]: '2_weeks',
  [StayDateAvailableJsonldReadStayDateAvailableStayType['2w']]: '2_weeks',
  [StayDateAvailableJsonldReadStayDateAvailableStayType.custom]: 'custom',
};

export const useContextualDataLayer = () => {
  const { data: localities } = useApiLocalitiesGetCollection();
  const currentDate = new Date();
  const { t } = useTranslation('mr');

  /**
   * Get capacity range string for a given number of persons
   */
  const getCapacityRange = (persons: number): string => {
    for (const [min, max, label] of CAPACITY_RANGES) {
      if (persons >= min && persons <= max) {
        return label;
      }
    }
    return '';
  };

  /**
   * Calculate the number of days between two dates
   */
  const calculateDaysBetween = (startDate: Date, endDate: Date): number => {
    return Math.ceil(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
  };

  /**
   * Format date to DD-MM-YYYY
   */
  const formatStandardDate = (date: Date): string => {
    return format(date, 'dd-MM-yyyy');
  };

  /**
   * Format the booking period (Month YYYY)
   */
  const formatBookingPeriod = (date: Date): string => {
    return (
      date.toLocaleString('default', { month: 'long' }) +
      ' ' +
      date.getFullYear()
    );
  };

  /**
   * Transforms search values into analytics data layer
   */
  const getDataLayer = (
    searchValues: SearchValues,
    initialDataLayer: AnalyticsDataLayer = {}
  ): AnalyticsDataLayer => {
    const dataLayer: AnalyticsDataLayer = { ...initialDataLayer };

    // Process composition data
    dataLayer.booking_number_people =
      searchValues?.composition?.persons ?? NO_PERSONS_VALUE_FLAG;
    dataLayer.booking_capacity = searchValues?.composition?.persons
      ? getCapacityRange(searchValues.composition.persons)
      : undefined;
    dataLayer.booking_dog =
      searchValues?.composition?.dogs ?? NO_DOGS_COUNT_VALUE_FLAG;

    // Process date information
    if (searchValues?.stayDate?.startDate || searchValues?.stayDate?.endDate) {
      if (searchValues?.stayDate?.startDate) {
        const startDateObj = new Date(searchValues.stayDate.startDate);
        dataLayer.booking_outbound_date = formatStandardDate(startDateObj);
        dataLayer.booking_period = formatBookingPeriod(startDateObj);
        dataLayer.booking_booking_date = formatStandardDate(currentDate);
        dataLayer.booking_horizon = calculateDaysBetween(
          currentDate,
          startDateObj
        );
      } else {
        dataLayer.booking_outbound_date = NO_DATE_VALUE_FLAG;
      }

      if (searchValues?.stayDate?.endDate) {
        dataLayer.booking_inbound_date = formatStandardDate(
          new Date(searchValues?.stayDate?.endDate)
        );
      } else {
        dataLayer.booking_inbound_date = NO_DATE_VALUE_FLAG;
      }

      if (
        searchValues?.stayDate?.startDate &&
        searchValues?.stayDate?.endDate
      ) {
        dataLayer.booking_length_of_stay = calculateDaysBetween(
          new Date(searchValues?.stayDate?.startDate),
          new Date(searchValues?.stayDate?.endDate)
        );
      }
    } else {
      dataLayer.booking_outbound_date = NO_DATE_VALUE_FLAG;
      dataLayer.booking_inbound_date = NO_DATE_VALUE_FLAG;
      dataLayer.booking_booking_date = NO_BOOKING_DATE_VALUE_FLAG;
    }

    // Process stay type
    if (
      searchValues?.stayDate?.stayType &&
      STAY_TYPE_MAPPING[searchValues?.stayDate?.stayType]
    ) {
      dataLayer.booking_holiday_break =
        STAY_TYPE_MAPPING[searchValues?.stayDate?.stayType];
    }

    // Process location
    dataLayer.booking_location =
      searchValues?.localityId && localities?.['hydra:member']
        ? (localities['hydra:member'].find(
            (l) => l.id === searchValues?.localityId
          )?.name ?? t(defaultLocalityLabel))
        : t(defaultLocalityLabel);

    return dataLayer;
  };

  /**
   * Updates the data layer in localStorage
   */
  const updateDataLayer = (data: SearchValues): void => {
    try {
      const storedDataLayer = localStorage.getItem('ContextualDataLayerGA4');
      const existingDataLayer: AnalyticsDataLayer = storedDataLayer
        ? JSON.parse(storedDataLayer)
        : {};

      const updatedDataLayer = getDataLayer(data, existingDataLayer);

      localStorage.setItem(
        'ContextualDataLayerGA4',
        JSON.stringify(updatedDataLayer)
      );
    } catch (error) {
      console.error('Error updating data layer:', error);
    }
  };

  return {
    updateDataLayer,
    getDataLayer,
  };
};
