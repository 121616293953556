import { alertAnatomy } from '@chakra-ui/anatomy';
import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const successBase = status === 'success' && {
    container: {
      borderRadius: '4px',
      border: '1px solid',
      bg: 'success.100',
      borderColor: 'success.400',
      color: 'success.900',
    },
  };

  const warningBase = status === 'warning' && {
    container: {
      bg: 'warning.100',
      borderColor: 'warning.400',
      color: 'warning.900',
      border: '1px solid',
      borderRadius: '4px',
    },
  };

  const errorBase = status === 'error' && {
    container: {
      bg: 'error.100',
      borderColor: 'error.400',
      color: 'error.900',
      border: '1px solid',
      borderRadius: '4px',
    },
  };

  const infoBase = status === 'info' && {
    container: {
      background: 'warning.300',
      color: 'warning.200',
      borderColor: 'warning.200',
      border: '1px solid',
      borderRadius: '4px',
    },
  };

  return {
    ...successBase,
    ...warningBase,
    ...errorBase,
    ...infoBase,
  };
});
export const alertTheme = defineMultiStyleConfig({ baseStyle });
