import { FieldError } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { useTranslation } from '@ae/shared';
import { UIAlertMessage } from '@ae/shared-ui';

type ErrorFieldProps = {
  errorField: FieldError;
};

export const ErrorField = ({ errorField }: ErrorFieldProps) => {
  const { t } = useTranslation('profile');

  // TODO: this is linked to profile namespace and should be more generic..
  let description = t('profil.error_data2');
  if (errorField.message) description = errorField.message;
  if (errorField.type === 'required') description = t('profil.error_data4');
  if (errorField.type === 'pattern') description = t('profil.error_data3');
  if (errorField.type === 'validateIban') description = t('profil.error.data5');
  if (errorField.type === 'validatePhoneNumber')
    description = t('profil.error_data6');
  if (errorField.type === 'validatePostalCode')
    description = t('profil.error_data3');

  return (
    <Box mb="20px">
      <UIAlertMessage description={description} status="error" displayIcon />
    </Box>
  );
};
