//TODO: try to use useNumberInput from Chakra. Currently making visual tests crashing.
import { useRef, useState } from 'react';
import { HStack } from '@chakra-ui/react';
import {
  UIButton,
  UIButtonProps,
  UIInput,
  useLongPressTicker,
} from '@ae/shared-ui';

const Button = ({
  label,
  onTrigger,
  ...props
}: {
  onTrigger: () => void;
  label: string;
} & UIButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  useLongPressTicker(ref, onTrigger);

  return (
    <UIButton
      ref={ref}
      h="40px"
      w="40px"
      p={0}
      borderRadius="50px"
      fontSize="24px"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onTrigger();
      }}
      {...props}
    >
      {label}
    </UIButton>
  );
};

type Props = {
  addButtonAriaLabel?: string;
  removeButtonAriaLabel?: string;
  inputAriaLabel?: string;
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
};

export const UINumericalPicker = ({
  addButtonAriaLabel,
  removeButtonAriaLabel,
  inputAriaLabel,
  value: defaultValue,
  min,
  max,
  onChange,
}: Props) => {
  const [value, setValue] = useState(defaultValue ?? 0);

  const onMinus = () => {
    if (min === undefined || value > min) {
      setValue(value - 1);
      onChange(value - 1);
    }
  };

  const onPlus = () => {
    if (max === undefined || value < max) {
      setValue(value + 1);
      onChange(value + 1);
    }
  };

  const minusDisabled = min !== undefined && min === value;
  const plusDisabled = max !== undefined && max === value;

  return (
    <HStack>
      <Button
        aria-label={removeButtonAriaLabel}
        label="-"
        fontSize="17px"
        onTrigger={onMinus}
        disabled={minusDisabled}
        transition="none"
      />
      <UIInput
        aria-label={inputAriaLabel}
        w="48px"
        p={0}
        mb="0"
        textAlign="center"
        value={value}
        isReadOnly
      />
      <Button
        aria-label={addButtonAriaLabel}
        label="+"
        fontSize="17px"
        onTrigger={onPlus}
        disabled={plusDisabled}
        transition="none"
      />
    </HStack>
  );
};
