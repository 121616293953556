export const i18nNamespaces = [
  'common',
  'mr',

  // ae-customer
  'home',
  'profile',
  'bookings',
  'payment_choice',
  'checkout',
  'favorites',
  'rental',
  'newsletter',
  'badges',

  // standalone
  'header',
  'footer',

  // apps
  'owner',
  'admin',
];
