'use client';

import { createContext, useContext } from 'react';

export interface RentalFavoritesTrackingContextState {
  listIndex: number | undefined;
  listName: string | undefined;
  listId: string | undefined;
}

export const RentalFavoritesTrackingContext =
  createContext<RentalFavoritesTrackingContextState>({
    listIndex: undefined,
    listName: undefined,
    listId: undefined,
  });

export const useRentalFavoritesTracking = () => {
  return useContext(RentalFavoritesTrackingContext);
};

export const RentalFavoritesTrackingProvider = ({
  value,
  children,
}: {
  value: RentalFavoritesTrackingContextState;
  children: React.ReactElement;
}) => {
  return (
    <RentalFavoritesTrackingContext.Provider value={value}>
      {children}
    </RentalFavoritesTrackingContext.Provider>
  );
};
