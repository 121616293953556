'use client';

import React, { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { parse as cookieParse } from 'cookie';

interface CookieContextState {
  cookie: Record<string, string | undefined>;
}

export const CookieContext = createContext<CookieContextState>({
  cookie: {},
});

export const useCookie = () => {
  const { cookie } = useContext(CookieContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies();

  const getCookie = (name: string) => {
    return cookie[name] ?? '';
  };

  return {
    getCookie,
    setCookie,
  };
};

export const CookieProvider = ({
  value,
  children,
}: {
  value: string | undefined;
  children: React.ReactElement;
}) => (
  <CookieContext.Provider value={{ cookie: cookieParse(value ?? '') }}>
    {children}
  </CookieContext.Provider>
);
